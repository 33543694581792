.notes_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}
.notes_title_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.notes_list_container {
  width: 100%;
  height: 100%;
}
.notes_list {
  display: flex;
  flex-direction: column;
}

.notes_list_item {
  font-family: Roboto;
  width: 100%;
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: column;
}

.notes_list_item_title {
  font-weight: bold !important;
  font-size: 11px;
}
.notes_list_item_details {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  overflow: scroll;
}
.notes_list_item_details_item {
  min-width: fit-content;
  height: 20px;
  margin-right: 5px;
}
.notes_list_item_details_item b {
  font-weight: bold;
}
.notes_inputs_container {
  display: flex;
  flex-wrap: wrap;
}

.notes_inputs_container input {
  width: 24%;
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding-left: 5px;
}
.notes_submit_container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.notes_submit_container button {
  transition: 0.5s all;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #fff;
  font-family: Roboto;
  padding: 2px 5px 2px 5px;
}
.notes_submit_container .rotate {
  border-radius: 50%;
  padding: 2px 2px 2px 2px;
  transform: rotate(360deg);
}
.notes_inputs_title {
  font-size: 11px;
  margin: 10px 0 5px;
}
.notes_input_item {
  font-size: 11px;
  min-height: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
}
.notes_input_item.title {
  width: 100%;
}
.notes_input_item.start_date {
  width: 50%;
}
.notes_input_item.end_date {
  width: 50%;
}
.notes_input_item.rating {
  margin: 10px auto 0;
  box-shadow: none;
  border: none;
}
.notes_input_item.note {
  border: 1px solid lightgrey;
  border-radius: 7px;
  width: 100%;
}
.notes_list_item_details_item img {
  height: 11px !important;
  margin-top: -2px;
}
.notes_advanced_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.notes_advanced_left {
  width: 100%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.notes_advanced_right {
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 100%;
}
.day_note_search_field {
  transition: 0.5s all;
  position: relative;
  width: 100%;
  height: 24px;
  color: #707070;	
  font-family: Roboto;	
  font-size: 12px;	
  font-style: italic;	
  letter-spacing: 0.33px;	
  line-height: 14px;
  margin-bottom: 5px;
}
.notes_advanced_new_note_input {
  width: fit-content;
  border: none;
  background-color: #fff;
  padding-left: 4px;
  color: #545669;	
  font-family: Roboto;	
  font-size: 12px;	
  font-weight: 500;	
  line-height: 14px;
  opacity: 0.53;
}
.notes_advanced_new_note_input::-webkit-clear-button {
  display: none;
}
.notes_advanced_new_note_textArea {
  border: 1px solid #CFD7DE;	
  border-radius: 7px;
  height: 50px;
}
.notes_advanced_left_header {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  color: black;
  font-family: Roboto;
}
.notes_advanced_left_header svg{
  position: absolute;
  right: 0;
  top: 0;
}
.notes_advanced_left_title {
  transition: 0.5s all;
}
.notes_advanced_left_header input {
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 4px;
  padding-right: 20px;
}
.notes_advanced_left_header input.blue{
  border: 1px solid #3FA5FF;
}
.notes_advanced_left_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notes_advanced_left_list span{
  color: #545669;	
  font-family: Roboto;	
  font-size: 12px;	
  font-weight: 500;	
  line-height: 14px;
  opacity: 0.55;
}
.past_note {
  margin: 6px 0 6px 0;
  padding: 5px;
  color: black;
  font-size: 12px;
  font-family: Roboto;
}
.past_note_hide textarea {
  max-height: 34px !important;
  overflow: hidden !important;
}
.past_note_show textarea {
  max-height: 200px !important;
  overflow: auto !important;
}
.past_note
  .MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid lightgrey !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14) !important;
}
.edit_note_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa5ff;
  margin-left: 3px;
  font-family: Roboto;
  width: fit-content;
  height: fit-content;
}

.notes_advanced_right_add_btn {
  position: absolute;
  right: 19px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa5ff;
  margin-left: 3px;
  font-family: Roboto;
  height: 33px;
  width: 35px;
  margin-bottom: 5px;
}
.notes_title_right {
  display: flex;
  flex-direction: column;
}
.custom_date_input {
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 4px;
  position: relative;
  width: 150px;
  margin-top: 5px;
}

.notes_modal_containter{
  position: absolute;
  max-width: 95%;
  min-width: 300px;
  background-color: #fff;
  height: fit-content;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 8px;
}

.notes_modal_title{
  width: 100%;
  margin-bottom: 5px;
  color: #232326;	
  font-family: Roboto;	
  font-size: 16px;	
  font-weight: bold;	
  letter-spacing: 0.5px;	
  line-height: 19px;
}

.notes_modal_item{
  width: 100%;
  margin-bottom: 5px;
}
.notes_modal_item div{
  color: #414248;	
  font-family: Roboto;	
  font-size: 14px;	
  font-weight: 500;	
  line-height: 16px;
}
.notes_modal_item input{
  width: 100%;
  border: 1px solid #CFD7DE;	
  border-radius: 7px;
  color: #545669;	
  font-family: Roboto;	
  font-size: 16px;	
  font-weight: 500;	
  line-height: 19px;
  padding: 2px 2px 3px 5px;
}
.notes_modal_item textarea{
  width: 100%;
  border: 1px solid #CFD7DE;	
  border-radius: 7px;
  color: #545669;	
  font-family: Roboto;	
  font-size: 16px;	
  font-weight: 500;	
  line-height: 19px;
  padding: 2px 2px 3px 5px;
}
.notes_modal_containter button{
  border: 1px solid #AAC8FF;
  border-radius: 4px;	
  background-color: #3FA5FF;	
  box-shadow: 0 2px 4px 0 rgba(25,50,89,0.2);
  color: #FFFFFF;	
  font-family: Roboto;	
  font-size: 14px;	
  font-weight: 500;	
  line-height: 16px;	
  height: 33px;	
  width: 118px;
}