.value_container {
  width: 100%;
  position: relative;
  border: 2px solid #9899a5;
  border-radius: 2px;
  padding: 5px 2px 2px 2px;
  margin: 3px 0 6px 0;
  min-width: 65px;
}
.value_title {
  position: absolute;
  top: -8px;
  text-transform: capitalize;
  left: 3px;
  padding: 0 2px 0 2px;
  background-color: #fff;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.31px;
  line-height: 11px;
}
.value_text {
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.38px;
  line-height: 14px;
  max-height: 40px;
  overflow: auto;
}