.sleep_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}
.sleep_title_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.sleep_list_container {
  width: 100%;
  height: 100%;
}
.sleep_list {
  display: flex;
  /* flex-direction: column; */
}

.sleep_list_item {
  font-family: Roboto;
  width: 100%;
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: column;
}

.sleep_list_item_title {
  font-weight: bold !important;
  font-size: 11px;
}
.sleep_list_item_details {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  overflow: scroll;
}
.sleep_list_item_details_item {
  min-width: fit-content;
  height: 20px;
  margin-right: 5px;
}
.sleep_list_item_details_item b {
  font-weight: bold;
}
.sleep_inputs_container {
  display: flex;
  flex-wrap: wrap;
}

.sleep_inputs_container input {
  width: 24%;
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding-left: 5px;
}
.sleep_submit_container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.sleep_submit_container button {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #fff;
  font-family: Roboto;
  padding: 3px 10px;
}
.sleep_inputs_title {
  font-size: 11px;
  margin: 10px 0 5px;
}
.sleep_input_item {
  font-size: 11px;
  min-height: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
}
.sleep_input_item.title {
  width: 60%;
}
.sleep_input_item.date {
  width: 40%;
}
.sleep_input_item.rating {
  margin: 10px auto 0;
  box-shadow: none;
  border: none;
}
.sleep_input_item.note {
  border: 1px solid lightgrey;
  border-radius: 7px;
  width: 100%;
}
.sleep_list_item_details_item img {
  height: 11px !important;
  margin-top: -2px;
}

/* overrides for rc-slider */
.rc-slider-disabled {
  background-color: transparent !important;
}

.rc-slider-vertical {
  align-self: center;
}

.range_selected {
  width: 40px !important;
}
.date_icon_wrapper {
  background-color: #ff8a62;
  border-radius: 20%;
  padding: 2px;
  margin-left: 10px !important;
  margin-right: 5px;
}
.date_icon_wrapper_basic {
  background-color: #3b4680;
  border-radius: 20%;
  padding: 2px;
  margin-left: 10px !important;
  margin-right: 5px;
}
.date_icon {
  filter: invert(1);
  font-size: 32px !important;
}
.night_icon {
  background-color: #3b4680;
  border-radius: 20%;
  padding: 5px;
  margin-left: 10px !important;
  margin-right: 5px;
}
.day_icon {
  background-color: #fbde8d;
  border-radius: 20%;
  padding: 5px;
  margin-left: 10px !important;
  margin-right: 5px;
}

.advanced_input_fields_container {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
}
.sleep_submit_container button {
  transition: 0.5s all;
}
.sleep_submit_container .rotate {
  transform: rotate(360deg);
  width: 32px;
  padding: 3px;
  border-radius: 50%;
}
