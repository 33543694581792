.MuiPaper-rounded{
    border-radius: 10px !important;
}
.MuiDialogContent-root{
    max-width: 450px;
}
.MuiOutlinedInput-input{
    padding-right: 5px !important;
}
.MuiOutlinedInput-root{
    border-radius: 7px !important;
}