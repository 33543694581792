.aboutpage_container {
  width: 100%;
  font-family: Roboto;
  height: 100vh;
  background-color: #fff;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_back_btn {
  display: flex;
  position: absolute;
  left: 20px;
  top: 20px;
}
.about_info_p1 {
  text-align: center;
  font-size: 25px;
}
.about_info_img {
  position: relative;
  max-width: 100%;
}
.about_info_p2 {
  font-size: 20px;
}
.aboutpage_title {
  font-size: 30px;
  font-weight: bold;
}
.aboutpage_text {
  margin: 20px 30px 10px 30px;
}
.aboutpage_text p {
  text-indent: 25px;
}
