.ListItem {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  display: flex;
}
.btn {
  color: white;
  text-align: center;
  background: #ffffff;
  font-size: 18px;
  width: 100%;
  border: none;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4),
    inset 0 7px 9px -7px rgba(0, 0, 0, 0.4),
    inset 0 -7px 9px -7px rgba(0, 0, 0, 0.4);
}
.btn:hover {
  color: white;
}
.right-side {
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.left-side {
  height: 100%;
  width: 25%;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.details {
  background-color: #3b89e6;
}
.any {
  background-color: #ffeb4f;
}
.delete {
  height: 100%;
  background-color: #ff3e2b;
}
.history {
  background-color: #3dc21b;
}

.grey-out{
  background-color: gray;
}

.BouncingListItem {
  transition: transform 0.5s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4),
    inset 0 -7px 9px -7px rgba(0, 0, 0, 0.4);
  background-color: white;
  box-sizing: border-box;
}

.Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}
