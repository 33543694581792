.active_minute_steps_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  vertical-align: top;
}

.active_minute_steps_chart {
  flex-grow: 2;
}

.active_minute_steps_controls {
  display: flex;
  flex-direction: column;
  max-width: 150px;
}

.active_minute_steps_fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active_minute_steps_fields input {
  border: 1px solid #676767;
  border-radius: 4px;
  margin: 10px 0 10px 0;
  width: 80px;
  padding-left: 5px;
}

.active_minute_steps_fields button {
  border: 1px solid #aac8ff;
  margin: 0;
  width: 80px;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #fff;
  transition: 0.5s all;
}

.active_minute_steps_fields .rotate {
  transform: rotate(360deg);
  width: 32px;
  padding: 3px;
  border-radius: 50%;
}

.steps_basic_input_container {
  width: 100%;
  border-radius: 4px;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fa;
}

.steps_basic_input_block {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  margin: 10px;
  position: relative;
}

.steps_basic_input_note_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #414248;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: 78%;
}

.steps_basic_input_note_container input {
  height: 30px;
  color: #414248;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  padding-left: 5px;
}

.steps_basic_input_steps_block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #414248;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: 20%;
}

.steps_basic_input_steps_block input {
  text-align: center;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: bold;
  width: 100%;
}

.steps_basic_save_cancel_block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 10px 0;
}
.steps_basic_save_cancel_block button:first-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 3px 8px 3px 8px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa5ff;
  font-family: Roboto;
  margin-right: 5px;
}
.steps_basic_save_cancel_block button:last-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 3px 8px 3px 8px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #fff;
  font-family: Roboto;
  transition: 0.5s all;
}
.steps_basic_save_cancel_block > button.rotate {
  transform: rotate(360deg);
}
