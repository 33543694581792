/* hubsite Covid19.css */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");

.cov19_hs_body {
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 0 30px 30px;
}

.cov19_hs_announcment_pt1 {
  color: #545669;
  font-family: Roboto;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 26px;
  text-align: center;
}

.cov19_hs_announcment_pt2 {
  margin-top: 20px;
  color: #414248;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.56px;
  line-height: 26px;
}

.cov19_hs_announcment_pt2 > span {
  color: #f38938;
  font-weight: 500;
  font-style: italic;
}

.cov19_hs_seg_title {
  color: #232326;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.69px;
  line-height: 25px;
  margin-top: 32px;
}

.cov19_hs_seg_container {
  position: relative;
  margin-top: 14px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
}

.cov19_hs_seg_container_top {
  display: flex;
  flex-wrap: wrap;
  /* height: 88px; */
}

.cov19_hs_expand {
  position: absolute;
  font-size: 15px;
  bottom: 3px;
  right: 5px;
  color: black;
  transition: 1s all;
}

.cov19_hs_expand.rotate {
  transform: rotate(180deg);
}

.cov19_hs_seg_container_bottom {
  display: flex;
  transition: all 1s ease-in-out;
  overflow: hidden;
  height: auto;
  max-height: 600px;
  padding: 10px;
}

.cov19_hs_seg_container_bottom.expanded {
  max-height: 0px;
  padding: 0px;
}
.cov19_hs_seg_num_block {
  height: 88px;
  width: 88px;
  border-radius: 10px;
  background-color: #dbeeff;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.cov19_hs_seg_num_block > div {
  color: #232326;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 28px;
}

.cov19_hs_seg_titleblock {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px 17px;
}

.cov19_hs_title_text {
  color: #232326;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 21px;
}

.cov19_hs_link_btn_wrap {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  flex-shrink: 0;
  margin-right: 10px;
}

.cov19_hs_seg_data_block_vertic {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  justify-content: center;
}

.cov19_hs_seg_data_block_horiz {
  display: flex;
  padding-left: 20px;
}

.cov19_hs_link_btn_text {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 8px 17px;
  flex-shrink: 0;
  white-space: nowrap;
}

.cov19_hs_seg_data_text {
  color: #b4b8bb;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  display: flex;
  padding: 5px 0;
}

.cov19_hs_upd_wrap {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.cov19_hs_upd_grey {
  color: #b4b8bb;
  font-family: Roboto;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: 5px;
}

.cov19_hs_upd_black {
  color: #545669;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.cov19_hs_horiz_wrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.cov19_hs_dsb_img {
  height: 68px;
}
.cov19_hs_black_text {
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
}

.cov19_hs_grey_text {
  color: #b4b8bb;
  font-family: Roboto;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.cov19_hs_seg_exp_inner_container {
  width: 100%;
}

.cov19_hs_seg_1_pt1 {
  display: flex;
  justify-content: space-between;
}

.cov19_hs_seg_1_pt1_text {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 10px;
}
.cov19_hs_links_cont {
  display: flex;
}
.cov19_hs_links_title {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  margin: 30px 0 10px;
}

.cov19_hs_sharer_block {
  display: flex;
  margin-top: 30px;
}

.cov19_hs_dsb_img_bigger {
  height: 90px;
}

.cov19_hs_sharer_badge_wrap {
  margin-right: 10px;
}
.cov19_hs_sharer_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.cov19_hs_sharer_text {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
.cov19_hs_graph_text {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
.cov19_hs_seg_container_top_start {
  display: flex;
}
.cov19_hs_link_btn_wrap {
  cursor: pointer;
}

@media all and (max-width: 600px) {
  .cov19_hs_announcment_pt1 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 26px;
  }
  .cov19_hs_announcment_pt2 {
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 20px;
  }
  .cov19_hs_seg_num_block {
    height: 70px;
    width: 70px;
  }
  .cov19_hs_seg_data_block_horiz {
    padding-left: 0;
  }
  .cov19_hs_links_cont {
    flex-direction: column;
    justify-content: flex-start;
  }
  .cov19_hs_title_text {
    font-size: 16px;
  }
  .cov19_hs_seg_container_bottom {
    padding-bottom: 30px;
  }
  .cov19_hs_link_btn_text {
    padding: 4px 10px;
  }
  .cov19_hs_link_btn_wrap {
    margin-right: 0;
    margin-top: 5px;
  }
  .cov19_hs_black_text {
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 20px;
  }
  .cov19_hs_grey_text {
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 20px;
  }
  .cov19_hs_upd_black {
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 14px;
  }
  .cov19_hs_upd_grey {
    font-size: 12px;
    line-height: 12px;
  }
  .cov19_hs_body {
    padding: 0 10px 10px;
  }
}
