/* -------------------------------------------------------------- */
/* Navigator component */
/* -------------------------------------------------------------- */

#navigator_background_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
}

#navigator_shop_logo {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 107px;
  z-index: 10;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.7))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  border-radius: 0px 0px 30px;

  max-width: 50%;
  background-color: white;
}

.navigator_util_logo_underline_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 48vw;
  height: 108px;
  background-color: white;
  opacity: 0.5;
  border-radius: 30px 0 30px 0;
  z-index: 5;
}

.navigator_round_corner_background {
  background-color: white;
  border-radius: 0px 25vw 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 127px);
  /* z-index: 20; */
}

.navigator_floorplan_title_class {
  color: black;
  font-size: 17pt;
  padding: 20px 2px 3px 15px;
}

.navigator_searchfield_class {
  position: relative;
  left: 18px;
  top: 10px;
  padding: 3px;
  font-size: 15px;
  width: 60%;
}

.navigator_searchfield_class::placeholder {
  color: orangered;
}

.navigator_login_register {
  position: absolute;
  right: 10px;
  background: white;
  color: black;
  border-radius: 3px;
  margin-top: 10px;
  padding: 10px;
}

.navigator_suggestions_container {
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
  margin-top: 10px;
  /* position: absolute; */
  width: 100vw;
}

.navigator_suggestions_wrapper {
  /* position: relative; */
  /* left: 8px; */
  /* top: 101px; */
  z-index: 4;
  grid-area: b;
}

.navigator_clear_X_btn {
  position: relative;
  top: 10px;
  right: 10px;
}

.navigator_return_to_search_btn {
  position: relative;
  top: 18px;
  left: 20px;
  height: 15px;
  margin-right: 10px;
  z-index: 4;
  grid-area: a;
}

.navigator_arrow_btn_container {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 10px;
  width: 60vw;
}

.navigator_arrow_btn_wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 10px;
  width: 60vw;
}

.navigator_arrow_btn {
  font-family: Verdana;
  width: 30px;
  height: 30px;
  margin: 10px;
}

.navigation_view_switch {
  display: flex;
  margin: 10px 0;
  height: 25px;
}

.navigation_view_switch img {
  width: 20px;
  height: 20px;
  align-self: center;
}

.navigation_view_switch_list {
  display: flex;
  justify-content: center;
  width: 35px;
  border: 1px solid grey;
  border-radius: 5px 0 0 5px;
}

.navigation_view_switch_carousel {
  display: flex;
  justify-content: center;
  width: 35px;
  border: 1px solid grey;
  border-radius: 0 5px 5px 0;
}

/* -------------------------------------------------------------- */
/* NavMap component */
/* -------------------------------------------------------------- */

/* #NavMap {
} */

.nav_map_container {
  /* margin-top: 10px; */
  position: relative;
  overflow: scroll;
}

/* .nav_map_container {
  margin-top: 10px;
  position: relative;
  overflow: scroll;

  width: 300px;
  height: 190px;
  overflow: hidden;
  border-radius: 0 10px 10px 10px;
  border: 1px solid #A7D3FF;
  box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
} */

.selector_div_class {
  position: absolute;
}

.green_selector_img_class {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 40;
}

.blink_animation_class {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  animation-iteration-count: 30;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.arrow_div_class {
  position: absolute;
  top: 190px;
  left: 262px;
  transform: rotate(-30deg);
  /* z-index: 40; */
}

.green_arrow_img_class {
  width: 40px;
  height: 30px;
}

/* -------------------------------------------------------------- */
/* Suggestions component */
/* -------------------------------------------------------------- */

.suggestions_block {
  position: absolute;
  width: 90%;
  border: 1px solid #d0d7e4;
  margin-top: -3px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
}

.suggestions_divider {
  width: 95%;
  margin-left: 2.5% !important;
  margin-bottom: 5px !important;
}

.suggestions_filters {
  padding: 0 10px 0 10px;
  margin: 10px 0 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.suggestion_filters_type {
  display: flex;
  width: 50%;
  justify-content: space-between;
}
.suggestion_filters_type > button {
  height: 34px;
  width: 46px;
}
.suggestions_filters_btn_product {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  justify-content: center;
}
.suggestions_filters_btn_product > img {
  height: 100%;
}
.suggestion_filters_view {
  display: flex;
  width: 30%;
  justify-content: space-around;
}
.suggestion_filters_view > button {
  width: 34px;
  height: 23px;
  margin: auto;
}
.suggestions_filters_btn_list {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  padding: 0px;
}
.suggestions_filters_btn_list > img {
  margin-top: -5px;
}

.btn_active {
  color: #fff;
  background-color: #3fa5ff;
}

.btn_passive {
  color: #000;
  background-color: #fff;
}
.suggestions_item_list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
  overflow: scroll;
  max-height: 50vh;
}
.suggestion_pagination {
  width: 100%;
  text-align: center;
}
.suggest_group_item_block {
  width: 50%;
  text-align: center;
  color: #545669;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
  margin-bottom: 10px;
}
.suggest_group_item_img {
  width: 150px;
  min-height: 150px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d7e4;
  margin-bottom: 10px;
  padding: 10px;
}

.suggest_dept_item_block {
  padding: 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;
  width: 90%;
  height: 60px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d7e4;
  margin: 10px 5% 15px 5%;
}
.suggest_dept_item_img {
  height: 60px;
}
.suggest_dropd_item {
  padding: 10px;
  border-bottom: 2px solid white;
  height: fit-content;
  max-width: calc(100vw - 20px);
  display: flex;
  flex-wrap: nowrap;
}
.suggest_dropd_item span {
  width: 100%;
  flex-wrap: wrap;
}
.list_item_img {
  width: 23px;
  height: 23px;
  margin-right: 5px;
}
.suggest_dropd_item_next_arrow {
  padding: 10px;
  border-bottom: 2px solid white;
  height: 45px;
  max-width: calc(100vw - 20px);
}

.suggest_icon_img {
  height: 18px;
  margin-right: 10px;
}

#Suggestions {
  margin: 0 5px;
  width: calc(100vw - 10px);
  overflow-x: auto;
  min-height: 50px;
  background-color: lightyellow;
  padding: 2px;
  font-size: 15px;
  scroll-behavior: auto;
}

#Suggestions_selected {
  min-height: 50px;
  max-width: calc(100vw - 40px);
  overflow-x: auto;
  padding: 2px;
  color: rgb(47, 122, 207);
  font-size: 15px;
  scroll-behavior: auto;
  font-style: italic;
}

.suggestions_load_carousel_item {
  width: 80px;
  height: 165px;
  display: flex;
  justify-content: center;
  margin: 10px;
  background-color: white;
  border: 1px solid lightgrey;
}

.suggestions_load_carousel_img_wrapper {
  align-self: center;
}

.suggest_load_more {
  width: 40px;
  margin: 10px;
  transform: rotate(180deg);
}

.suggest_load_more_v2 {
  margin-top: -10px;
  margin-left: 50%;
  transform: rotate(-90deg);
}

.suggestions_carousel_container {
  overflow-x: auto;
  padding: 5px;
  font-size: 12px;
  margin-top: 5px;
}

.suggestions_carousel_wrapper {
  display: flex;
}

.suggestions_carousel_item {
  width: 120px;
  height: 165px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  border: 1px solid lightgrey;
}

.suggestions_carousel_img_wrapper {
  max-width: 118px;
  align-self: center;
  margin-bottom: 5px;
}

.suggestions_carousel_item_img {
  max-width: 118px;
  min-width: 80px;
  height: 130px;
  background: transparent url("/assets/images/loading.gif") center no-repeat;
}

.suggestions_carousel_item_price {
  align-self: center;
  color: rgb(0, 89, 255);
  font-weight: 500;
}

.suggestions_group_carousel_item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  border: 2px solid lightblue;
  background-color: white;
  border-radius: 5px;
}

.suggestions_group_carousel_img_wrapper {
  max-width: 120px;
  align-self: center;
  margin-bottom: 5px;
  text-align: center;
  font-size: 15px;
  padding: 5px;
}

.suggest_icon_img_filter {
  margin-left: 65px;
  width: 28px;
  margin-bottom: 10px;
}

/* -------------------------------------------------------------- */
/* BookDetails component */
/* -------------------------------------------------------------- */

/* #BookDetails {
  
} */

.book_details_stock_container {
  padding: 0 20px;
}

.book_details_data_content_container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.book_details-item-image {
  height: 200px;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
}

.book_details_book_title {
  font-weight: 500;
  font-size: 18px;
  overflow-y: auto;
  width: 250px;
  text-align: center;
  max-height: 54px;
  background-color: transparent;
  border: none;
  color: black;
  -webkit-text-fill-color: black;
  opacity: 1;
}

.book_details_border_box {
  margin-top: 20px;
  padding: 6px 10px;
  min-width: 300px;
  max-width: 100vw;
  min-height: 150px;
  border: 1px solid black;
}

.book_details_stock_status {
  margin-bottom: 20px;
}

.book_details_order_box {
  display: flex;
  justify-content: flex-end;
}

.book_details_price_container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 80vw;
}

.book_details_invisible {
  width: 40px;
}

.book_details_price {
  font-size: 40px;
}

.book_details_cart_image {
  height: 60px;
}

/* -------------------------------------------------------------- */
/* Filter component */
/* -------------------------------------------------------------- */

#Filter {
  display: flex;
  padding: 0 0 0 20px;
  width: 100vw;
  background-color: lightyellow;
  border-bottom: 1px solid lightgray;
}

.filter_icons_container {
  overflow-x: auto;
}

.filter_icon_img {
  height: 18px;
  margin: 12px 10px 6px 0;
}

.filter_selected {
  filter: invert(1) brightness(0.5) sepia(1) saturate(10000%) hue-rotate(120deg);
}

.filter_icon_img_filter {
  height: 38px;
  width: 28px;
  padding: 12px 2px 6px 8px;
  border-right: 1px solid lightgray;
  margin-right: 5px;
}

/* -------------------------------------------------------------- */
/* LOGIN MODAL component */
/* -------------------------------------------------------------- */

.navigation_login_container {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(55, 123, 248);
  padding: 10px 10px 20px 10px;
  max-width: 320px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  position: relative;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
}

.navigation_login_close {
  /* float: right; */
  font-size: 28px;
  font-weight: bold;
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 101;
}
.navigation_login_main_title {
  text-align: center;
  color: white;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
}

.navig_login_label {
  color: white;
  font-size: 20px;
  font-weight: 700;
  align-self: center;
  margin: 0;
}

.navig_login_input {
  overflow: hidden;
  color: rgb(55, 123, 248);
  font-size: 20px;
  font-weight: 700;
  padding-left: 20px;
  box-shadow: inset 0 0 3px #c9c9c9;
  height: 40px;
  width: 70%;
  align-self: center;
}

.navig_login_submit {
  margin-top: 30px;
  width: 90%;
  height: 50px;
  box-shadow: 0px 5px 14px -7px #000000;
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9',GradientType=0);
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  color: #666666;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  align-self: center;
  text-align: center;
  padding: 10px;
}

.navigation_login_new_here_text {
  margin: 10px 0;
  color: white;
  align-self: center;
}

.navigation_login_register_btn {
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  border: 2px dotted white;
  padding: 5px 10px;
  height: 60px;
  width: 90%;
  border-radius: 15px;
}

.navigation_login_register_btn div {
  color: white;
  font-size: 15px;
}

.navigation_login_register_btn div:nth-of-type(2) {
  padding-top: 10px;
}

.navigation_login_confirm_message {
  padding: 7px;
  font-weight: 500;
}
