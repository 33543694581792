.active_minute_nutrition_container{
    display: flex;
    flex-direction: row;
    width: 100%;
    vertical-align: top;
}

.active_minute_nutrition_chart{
    flex-grow: 2;
}

.active_minute_nutrition_controls{
    display: flex;
    flex-direction: column;
    max-width: 150px;
}

.active_minute_nutrition_fields{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.active_minute_nutrition_fields input{
    border: 1px solid #676767;	
    border-radius: 4px;
    margin: 10px 0 10px 0;
    width: 110px;
    padding-left: 5px;
}

.active_minute_nutrition_fields button{
    border: 1px solid #AAC8FF;	
    margin: 0;
    width: 110px;
    border-radius: 4px;
    background-color: #3FA5FF;	
    box-shadow: 0 2px 4px 0 rgba(25,50,89,0.2);
    color:#fff;
    transition: 0.5s all;
}

.active_minute_nutrition_fields .rotate{
    transform: rotate(360deg);
    width: 32px;
    padding: 3px;
    border-radius: 50%;
}