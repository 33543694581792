@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

.assistance {
  // width: 100vw;
  background: #fff;
  min-height: 100vh;
  .header {
    background: white;
    width: 100%;
    display: flex;
    padding-bottom: 5%;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: fixed;
    top: 0;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 80%,
      rgba(255, 255, 255, 0) 100%
    );
    .logo_container {
      margin: 5px;
      height: 16vh;
      width: 100%;
      display: flex;
      justify-content: center;
      .logo {
        object-fit: contain;
        // height: 100%;
        max-height: 16vh;
        // width: 100%;
        max-width: 100%;
      }
    }
    .search {
      margin: 0 auto;
      width: 90%;
      border-radius: 7px;
      background: white;
      .paper {
        border: 1px solid #d0d7e4;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
        padding: 2px 4px;
        display: flex;
        align-items: center;
        height: 35px;
        .input {
          font-family: Roboto, sans-serif;
          font-style: italic;
          letter-spacing: 0.39px;
          line-height: 16px;
          font-size: 16px;
          margin-left: 10px;
          flex: 1;
        }
        // .icon-button {
        // }
      }
    }
  }

  .chat {
    top: calc(16vh + 50px);
    font-family: Roboto, sans-serif;
    height: 100%;
    position: relative;
    background: url("/assets/images/chat_bg.png");
    background-attachment: fixed;
    .virtassist_default_welcome {
      background-color: white;
      padding: 15px 5px 5px 5px;
      text-align: center;
      font-weight: 700;
      font-size: 15px;
    }
    .virtassist_default_map_preview {
      width: 100%;
      padding: 10px 5px;
      background-color: white;
    }
  }
  .chat::-webkit-scrollbar {
    display: none;
  }
}
