/* receipt_with_img.css */

/* SHOP MIRKA */
/* ˇˇˇˇˇˇˇˇˇˇˇˇˇˇ */

.css2receipt_with_imgs_container {
  display: grid;
  width: 320px;
  grid-template-areas:
    "a"
    "b"
    "c";
  margin: 20px auto;
  box-sizing: border-box;
  background-color: white;
  padding: 10px;
  --total_sum_desc: "CELKOM EUR";
}

#css2FOOTER_SUM_DESC_imgview::before {
  content: var(--total_sum_desc);
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css2second_view_sum_container {
  display: flex;
  justify-content: space-between;
}

#css2second_view_big_total_price {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css2shop_logo {
  grid-area: a;
  background-image: url("/assets/images/drogeriamirka.jpg");
  background-repeat: no-repeat;
  background-size: 100% 153px;
  width: 100%;
  height: 157px;
}

#imgs_container_inner_wrapper {
  grid-area: b;
  display: inline;
}

#css2shop_footer {
  grid-area: c;
}

.css2one_item_zone {
  float: left;
  width: 100px;
  height: 244px;
  background-color: lightgray;
  padding: 5px;
  border: 2px solid white;
}

.css2imgContainer {
  width: 86px;
  height: 86px;
  background-color: white;
  display: flex;
}

.css2imgContainer > img {
  width: 86px;
  max-height: 86px;
  align-self: center;
}

.css2secondViewItemDescription {
  overflow: hidden;
  line-height: 1.5em;
  height: 6em;
}

/* shop MuBuBu */
/* ˇˇˇˇˇˇˇˇˇˇˇˇˇˇ */

#css3shop_logo {
  grid-area: a;
  background-image: url("/assets/images/mububu.jpg");
  background-repeat: no-repeat;
  background-size: 100% 180px;
  width: 320px;
  height: 190px;
  margin: 0 auto;
}

.css3receipt_with_imgs_container {
  display: grid;
  grid-template-areas:
    "a"
    "b"
    "c";
  margin: 20px auto;
  box-sizing: border-box;
  background-color: white;
  padding: 10px;
  width: 420px;
  --total_sum_desc: "Summe";
}

#css3FOOTER_SUM_DESC_imgview::before {
  content: var(--total_sum_desc);
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css3imgs_container_inner_wrapper {
  grid-area: b;
  display: inline;
}

#css3shop_footer {
  grid-area: c;
}

.css3one_item_zone {
  display: flex;
  float: left;
  width: 100%;
  height: 100px;
  background-color: lightgray;
  padding: 5px;
  border: 2px solid white;
}

.css3imgContainer {
  width: 86px;
  height: 86px;
  margin-right: 10px;
}

.css3imgContainer > img {
  height: 86px;
}

.css3secondViewItemDescription {
  overflow: hidden;
  line-height: 1.5em;
  height: 60px;
  width: 250px;
}

#css3second_view_big_total_price {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css3second_view_sum_container {
  display: flex;
  justify-content: space-between;
}

.css3itemQuantityAndPrice {
  display: flex;
}

.css3itemQuantityAndPrice > div {
  margin-right: 20px;
}

.css3bookColPreviewBtn {
  margin: 13px 0 0 auto;
  width: 34px;
  font-size: 30px;
  font-weight: 800;
}

/* shop kempter */
/* ˇˇˇˇˇˇˇˇˇˇˇˇˇˇ */

#css4shop_logo {
  grid-area: a;
  background-image: url("/assets/images/kempter_logo.jpg");
  background-repeat: no-repeat;
  background-size: 100% 180px;
  width: 320px;
  height: 190px;
  margin: 0 auto;
}

.css4receipt_with_imgs_container {
  display: grid;
  grid-template-areas:
    "a"
    "b"
    "c";
  margin: 20px auto;
  box-sizing: border-box;
  background-color: white;
  padding: 10px;
  width: 420px;
  --total_sum_desc: "Summe";
}

#css4FOOTER_SUM_DESC_imgview::before {
  content: var(--total_sum_desc);
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css4imgs_container_inner_wrapper {
  grid-area: b;
  display: inline;
}

#css4shop_footer {
  grid-area: c;
}

.css4one_item_zone {
  display: flex;
  float: left;
  width: 100%;
  height: 100px;
  background-color: lightgray;
  padding: 5px;
  border: 2px solid white;
}

.css4imgContainer {
  width: 86px;
  height: 86px;
  margin-right: 10px;
}

.css4imgContainer > img {
  height: 86px;
}

.css4secondViewItemDescription {
  overflow: hidden;
  line-height: 1.5em;
  height: 60px;
  width: 250px;
}

#css4second_view_big_total_price {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css4second_view_sum_container {
  display: flex;
  justify-content: space-between;
}

.css4itemQuantityAndPrice {
  display: flex;
}

.css4itemQuantityAndPrice > div {
  margin-right: 20px;
}

.css4bookColPreviewBtn {
  margin: 13px 0 0 auto;
  width: 34px;
  font-size: 30px;
  font-weight: 800;
}

#receipt_bookcol_preview_wrapper {
  margin-top: 55px;
}

.isbnNullClass {
  visibility: hidden;
}
