.visit_container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
}
.visit_container_title {
  font-weight: bold;
}
.login_page_bg {
  opacity: 0.1;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 15%;
  filter: hue-rotate(215deg);
}
.visit_block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px 0 0 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin-top: 20px;
  color: #545669;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  background-color: #fff;
}
.visit_block_title {
  width: 190px;
  text-align: center;
  padding-bottom: 5px;
  color: #3fa5ff;
  border-bottom: 1px solid #3fa5ff;
}
.visit_input {
  border: 1px solid #676767;
  border-radius: 7px;
  height: 34px;
  padding-left: 10px;
  margin: 10px auto;
  width: 190px;
}
.visit_input::placeholder {
  font-style: italic;
}
.visit_block_main_controls {
  display: flex;
  width: 190px;
  justify-content: space-between;
}
.visit_block_main_controls button {
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 6px 10px 6px 10px;
  width: 90px;
}
.visit_block_main_controls .cancel {
  border: 1px solid #aac8ff;
  background-color: #ffffff;
  color: #3fa4ff;
}
.visit_block_main_controls .go {
  border: 1px solid #aac8ff;
  background-color: #3fa5ff;
  color: #fff;
}
.visited_hubs_list_container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 200px;
  overflow-y: auto;
}
.visited_hubs_list_title {
  width: 100%;
  margin-top: 15px;
  text-align: left;
  padding-left: 10px;
}
.visited_hubs_list_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
}
.visited_hubs_list_item:nth-child(2n) {
  background-color: #f2f5fa;
}
.visited_hubs_list_item_info {
  display: flex;
  align-items: center;
}
/* .visited_hubs_list_item_info .number{
    
} */
.visited_hubs_list_item_info .name {
  margin-left: 10px;
}
.visited_hubs_list_item_controls {
  display: flex;
}
.visited_hubs_list_item_controls button {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  font-weight: bold;
  min-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.visited_hubs_list_item_controls .white {
  border: 1px solid #3fa5ff;
  background-color: #fff;
  color: #3fa5ff;
  margin-right: 5px;
}
.visited_hubs_list_item_controls .blue {
  border: 1px solid #3fa5ff;
  background-color: #3fa5ff;
  color: #fff;
}
