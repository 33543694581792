#BottomNavigation {
  margin: 0;
}

#bn_rect_bg {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  height: 60px;
  background-color: white;
  width: 100vw;
  -webkit-box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-evenly;
  padding-right: 90px;
}

#bn_circ_bg {
  position: absolute;
  border-radius: 50%;
  z-index: 11;
  bottom: 0px;
  height: 90px;
  background-color: white;
  width: 90px;
  right: 0px;
}

.bn_standard_icon > img {
  width: 36px;
  margin: 12px;
}

/* #bn_home_icon > img {
} */

/* #bn_bookcol_icon > img {
} */

/* #bn_settings_icon > img {
} */

/* #bn_receipt_icon > img {
} */

/* #bn_plus_icon > img {
} */

/* #bn_scanner_icon > img {
} */

/* #bn_admin_login_icon > img {
} */

#bn_virt_assist_maps_icon > img {
  filter: invert(0.7);
}

#bn_profile_icon > img {
  width: 64px;
  margin: 14px;
  border: 1px solid black;
  padding: 5px;
  border-radius: 50%;
}
