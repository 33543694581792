@media (min-width: 100px) and (max-width: 767px) {
  .container {
    padding-top: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
}
@media (min-width: 768px){
  .container {
    width: 95%;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: left;
    flex-wrap: nowrap;
  }
}
.item {
  width: 80px;
  margin: 5px;
  perspective: 800px;
  min-height: 116px;
  height: fit-content;
  position: relative;
}
.MuiBadge-dot{
  height: 10px !important;
  width: 10px !important;
}
.MuiBadge-anchorOriginBottomRightRectangle{
  transition: 0.2s all !important;
  right: 3px !important;
  bottom: 0px !important;
}
.MuiBadge-colorPrimary{
  border: 1px solid #FFFFFF !important;	
  background-color: #3DCA39 !important;
}
.moveUp .MuiBadge-anchorOriginBottomRightRectangle{
  bottom: 15px !important;
}
.image {
  transition-property: transform box-shadow;
  transition-duration: 0.3s;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  border-right: 3px solid grey;
}
.image.reflection{
  -webkit-box-reflect: below 2px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.3), rgba( 0, 0, 0, 0.1 ) 10%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0));
}

.shadow {
  margin-top: 10px;
  height: 3px;
  width: 100%;
  background-color: lightgray;
  filter: blur(4px);
}
.category_title {
  font-size: 12px;
  text-align: center;
  min-height: 20px;
  font-weight: bold;
  margin-top: 2px;
}
