.active_minute_back_button {
  position: absolute;
  height: 19px;
  width: fit-content;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin: 10px 0 0 10px;
  z-index: 20;
}

.active_minute_settings_button {
  position: absolute;
  height: 19px;
  width: fit-content;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin: 10px 0 0 10px;
  z-index: 20;
  right: 20px;
}

.active_minute_title_text {
  position: relative;
  padding-top: 8px;
  text-align: center;
  width: 100%;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
}

.active_minute_block_title_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.active_minute_block_title_counter {
  position: relative;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  margin: 15px 5% 10px 0;
}

.active_minute_block_title_text {
  position: relative;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  margin: 15px 0 10px 5%;
}

.active_minute_search_container {
  position: relative;
  margin: 15px auto;
  margin-bottom: 20px;
  width: 90%;
  border-radius: 7px;
  background: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
}

.MuiPaper-rounded {
  border-radius: 7px !important;
}

.active_minute_search {
  border: 1px solid #d0d7e4 !important;
  padding: 2px 4px !important;
  display: flex !important;
  align-items: center !important;
  height: 35px !important;
  box-shadow: none !important;
}

.active_minute_input {
  font-family: Roboto, sans-serif;
  font-style: italic;
  letter-spacing: 0.39px;
  line-height: 16px;
  font-size: 16px;
  margin-left: 10px;
  flex: 1;
}

.active_minute_expansion_panel {
  width: 95% !important;
  margin: 5px auto !important;
  margin-bottom: 2px !important;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14) !important;
  border-radius: 10px !important;
  transition: 0.3s all !important;
}

.active_minute_expansion_panel img {
  margin-left: auto;
}

.active_minute_occasional_panel {
  position: relative;
  width: 95% !important;
  margin: 5px auto !important;
  margin-bottom: 10px !important;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14) !important;
  border-radius: 10px !important;
}

.active_minute_occasional_panel img {
  margin-left: auto;
}

.MuiExpansionPanel-root:before {
  display: none;
}

.MuiExpansionPanelSummary-root {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  min-height: 35px !important;
}

.MuiExpansionPanelSummary-content {
  margin: 0 !important;
}

.MuiExpansionPanelDetails-root {
  padding: 12px !important;
}

.bgColor {
  background-color: #3fa4ff !important;
  color: #fff !important;
}

.MuiCollapse-container {
  transition: height 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiExpansionPanelSummary-content {
  transition: margin 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiExpansionPanelSummary-root {
  transition: min-height 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiIconButton-root {
  padding: 0px !important;
}

.active_minute_bg {
  position: absolute;
  filter: hue-rotate(210deg);
  opacity: 0.15;
  width: 100vw;
}

.occasional_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.occasional_item {
  position: relative;
  height: fit-content;
  min-height: 125px;
  max-width: 85px;
  margin: 0 2px 2px 2px;
}

.occasional_item_title {
  position: relative;
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  width: 100%;
  overflow-wrap: break-word;
  text-align: center;
  margin-bottom: 5px;
  max-height: 40px;
  overflow-y: auto;
}

.occasional_item_img {
  position: relative;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 2px;
  border: 2px solid lightgrey;
  box-shadow: -6px 6px 31px 0 rgba(22, 25, 31, 0.13),
    0 2px 24px -11px rgba(22, 232, 255, 0.76);
}

.occasional_item_img.active {
  border: 2px solid #3fe0ff;
}

.occasional_image_icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.panel_icon {
  position: absolute;
  right: 5%;
  top: 0;
  padding: 10px;
  z-index: 1;
}

.panel_btn {
  position: absolute;
  right: 13%;
  top: 2px;
  z-index: 1;
  width: 50px;
  height: 30px;
  font-size: 8px;
  border: none;
  background-color: transparent;
  line-height: 0.9em;
  color: #3fa4ff;
}

.occasional {
  color: #fff;
}

.active_minute_modal_block {
  position: relative;
  min-width: 310px;
  max-width: 95vw;
  height: fit-content;
  background-color: #fff;
  border: 1px solid grey;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Roboto;
  color: #000;
  font-size: 16px;
  line-height: 20px;
}

.active_minute_modal_close {
  position: absolute;
  top: 4px;
  right: 6px;
}

.type_modal_title {
  color: #232326;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 28px;
  margin-bottom: 10px;
}
.type_modal_text {
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 16px;
  text-align: center;
  margin: 10px 0 10px 0;
}
.active_minute_modal_block button:first-of-type {
  height: 33px;
  width: 167px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa4ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.active_minute_modal_block button:last-of-type {
  height: 33px;
  width: 167px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.filters_container {
  width: 100%;
  display: flex;
  padding: 0 5px 0 5px;
  align-items: center;
  justify-content: space-between;
}
.filters_container input {
  border: 1px solid #e4e4e4;
  padding-left: 5px;
  padding-right: 20px;
  width: 100%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  height: 35px;
}
.filters_container select {
  height: 35px;
  margin-left: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
}

.search_categories_input {
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 25px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  height: 35px;
}

.one_time_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.one_time_item {
  position: relative;
  height: fit-content;
  min-height: 125px;
  max-width: 85px;
  margin: 0 2px 2px 2px;
}

.one_time_item_title {
  position: relative;
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  width: 100%;
  overflow-wrap: break-word;
  text-align: center;
  margin-bottom: 5px;
  max-height: 40px;
  overflow-y: auto;
}

.one_time_item_img {
  position: relative;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 2px;
  border: 2px solid lightgrey;
  box-shadow: -6px 6px 31px 0 rgba(22, 25, 31, 0.13),
    0 2px 24px -11px rgba(22, 232, 255, 0.76);
}
