/* ReceiptDisplay.css */

#ReceiptDisplay {
  background-image: url("/assets/images/james_bon_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.rec_disp_scan_btn {
  background: #3fa4ff;
  border-radius: 50%;
  width: 45vw;
  height: 45vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.rec_disp_scan_btn_text {
  align-self: center;
  color: white;
  font-size: 23px;
  font-weight: bolder;
  text-align: center;
}

#rec_disp_video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.rec_disp_receipt {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10%;
  height: 90%;
  width: 100%;
  overflow: auto;
}

.james_bon_logo {
  width: 30vw;
  left: 50%;
  top: 3vh;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
}

.receipt_display_info_btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
.receipt_display_center_btn {
  font-family: Roboto;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.receipt_display_impressum {
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: 1px solid #373737;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
  background-color: #373737;
  color: #fff;
  font-family: Roboto;
}

.rec_disp_cam_overlay {
  position: relative;
  background-image: url("/assets/images/james_bon_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  height: 100vh;
  width: 100%;
  z-index: 1;
  background-size: 202%;
}
@media (orientation: landscape) {
  .rec_disp_cam_overlay {
    background-size: 110%;
  }
}

.receipt_video {
  position: absolute;
  z-index: 1;
  clip-path: circle(48vmin);
}

.scan_next_qr_big {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3fa5ff;
  border: 6px solid #fff;
  border-radius: 80%;
  width: 150px;
  height: 85px;
  color: #fff;
  font-family: Roboto;
  font-weight: bold;
}

.scan_next_qr_big img {
  height: 25px;
}

.back_btn {
  position: absolute;
  top: 17px;
  left: 17px;
}
.back_btn a {
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
}
