/* BookcolStoreTab.css */

/* bst_ => prefix for BookcolOrderTab */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolStoreTab {
  position: absolute;
  font-family: "Ubuntu", sans-serif;
}

.bst_round_corner_background {
  background-color: white;
  border-radius: 0px 25vw 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  clip-path: inset(7vh 0 0 0);
  padding-top: 7vh;
}

#bst_top_bookshelf_bg {
  width: 100vw;
}

.bst_top_title {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.bst_book_data_wrapper {
  padding-top: 20px;
  width: 100vw;
}

.bst_item_ordered {
  height: 200px;
  margin-bottom: 10px;
  font-family: "Ubuntu", sans-serif;
}

.bst_title {
  font-weight: bold;
  font-size: 18px;
  max-height: 54px;
  overflow-y: auto;
}

.bst_publisher {
  margin-bottom: 10px;
  font-size: 15px;
  margin-left: 17px;
}

.bst_item_ordered_inner_wrapper {
  display: flex;
}

.bst_img_wrapper > img {
  border: 1px solid lightgray;
  height: 170px;
  margin-bottom: 20px;
}

.bst_line {
  border: 0;
  height: 2px;
  margin-bottom: 5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.bst_line_light {
  border: 0;
  height: 2px;
  margin-bottom: 5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
}

.bst_item_content {
  padding-left: 20px;
}

.bst_order_time {
  margin-top: 30px;
  font-size: 15px;
}

.bst_bestsellers_container {
  overflow-x: auto;
  padding: 5px;
  font-size: 12px;
  margin-top: 5px;
}

.bst_bestsellers_flex_wrapper {
  display: flex;
}

.bst_bestseller_item {
  width: 120px;
  height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
}

.bst_bestseller_img_wrapper {
  max-width: 120px;
  align-self: center;
  margin-bottom: 5px;
}

.sbt_book-item-image {
  max-width: 120px;
  min-width: 80px;
  height: 130px;
  background: transparent url("/assets/images/loading.gif") center no-repeat;
}

.bst_bestseller_item_price {
  align-self: center;
  color: rgb(0, 89, 255);
  font-weight: 500;
}
.bst_bestsellers_title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
}

.bst_bestseller_order_box {
  position: relative;
  height: 50px;
  background: lightblue;
  padding: 5px;
  display: flex;
}

.bst_navigation_redirect_btn {
  font-size: 14px;
  font-weight: 500;
  color: #0684df;
  text-align: end;
  margin-right: 10px;
  margin-top: 30px;
}
