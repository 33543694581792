/* ErrorBoundary.css */

.errBound_inner_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.errBound_inner_wrapper {
  border-radius: 10px;
  align-self: center;
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.errBound_text {
  align-self: center;
  margin-bottom: 20px;
}

.errBound_reload_btn {
  padding: 5px;
  border: 1px solid black;
  align-self: center;
}
