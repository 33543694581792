.blank_receipt_container {
  background-color: white;
  width: 420px;
  box-sizing: border-box;
  /* font-family: sans-serif; */
  font-family: "Oxygen Mono", monospace;
  /* font-family: "Courier New", Arial; */
  font-size: 19px;
  padding: 20px;
  margin: 5px auto;
  border: 1px solid lightgrey;
  box-shadow: 5px 5px 5px grey;
  letter-spacing: -1px;
}

.blank_logo {
  width: 150px;
  height: 150px;
  margin: 30px auto;
}

.blank_logo_container {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid lightgray;
  background: #ebf1f6; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #ebf1f6 0%,
    #abd3ee 50%,
    #89c3eb 51%,
    #d5ebfb 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #ebf1f6 0%,
    #abd3ee 50%,
    #89c3eb 51%,
    #d5ebfb 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #ebf1f6 0%,
    #abd3ee 50%,
    #89c3eb 51%,
    #d5ebfb 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebf1f6', endColorstr='#d5ebfb',GradientType=1 );
}

.blank_grid_container {
  display: grid;
  grid-template-areas:
    "a1 a1"
    "b1 b2"
    "c1 c2";
  grid-template-columns: 60% 40%;
}

.blank_text {
  margin-top: 60px;
  grid-area: a1;
  font-weight: bold;
}

.blank_text_0 {
  margin-top: 40px;
  grid-area: b1;
}

.blank_text_1 {
  margin-top: 40px;
  grid-area: b2;
  display: flex;
  justify-self: flex-end;
}

.blank_text_2 {
  margin-top: 40px;
  grid-area: c1;
}

.blank_text_3 {
  margin-top: 40px;
  grid-area: c2;
  display: flex;
  justify-self: flex-end;
}
