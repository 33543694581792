#SingleBookInfo {
  position: absolute;
}

.sbi_round_corner_background {
  background-color: white;
  border-radius: 0px 130px 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 127px);
  z-index: 1;
}

#sbi_top_bookshelf_bg {
  width: 100vw;
}

.sbi_book_data_wrapper {
  display: flex;
  flex-direction: column;
}

.sbi_book_img_wrapper {
  align-self: center;
  background-color: white;
  margin-top: -50px;
}

.sbi_book_img_wrapper > img {
  border: 10px solid white;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  height: 200px;
  margin-bottom: 20px;
}

.sbi_book_data_wrapper > div {
  align-self: center;
  margin: 5px 30px;
}
