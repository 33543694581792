.charts-screen{
    overflow: hidden;
    min-height: 100vh;
    background-color: white;
    border-radius: 0px 130px 0px 0px;
    margin-top: 10vh;
}

.bg-img{
    width: 100vw;
    position: absolute;
    z-index: -1;
    top: 0;
}

.charts-screen-chart-block{
    width: 100%;
    min-height: 20vh;
    margin-bottom: 40px;
}

.recharts-surface{
    margin-left: -10px !important;
}

.xAxis .recharts-layer.recharts-cartesian-axis-tick{
   font-size: 6pt;
}
.yAxis .recharts-layer.recharts-cartesian-axis-tick{
    font-size: 6pt;
}

.charts-screen-type {
    font-size: 1.2rem;
    margin-bottom: 0;
}

.charts-screen-tittle{
    font-size: 2rem;
    padding: 5px 15px;
}

.dropdown{
    width: 100%;
    margin-top: 4px;
    font-size: 0.9em;
}

.select{
    max-width: 90px !important;
}
.date-picker{
    max-width: 170px;
}
.controls{
    padding-top: 10px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.charts-screen-date-picker{
    flex: 1;
    display: flex;
    font-size: 12px;
    align-items: center;
}

.MuiToolbar-root{
    height: 80px !important;
}
.MuiPickersCalendar-transitionContainer{
    min-height: 190px !important;
}
.MuiTypography-h4{
    font-size: 1.8em !important;
}
.MuiTypography-subtitle1{
    font-size: 0.9em !important;
}
.MuiPickersBasePicker-pickerView{
    min-height: 200px !important;
    min-width: 290px !important;
}

.MuiPickersYearSelection-container{
    height: 200px !important;
}

.recharts-cartesian-axis-ticks{
    font-size: 0.8em !important;
}
.MuiInputBase-adornedEnd{
    font-size: 0.8em !important;
}

