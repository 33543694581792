/* Hubmanager.css */

#hubmanager_top_bookshelf_bg {
  width: 100vw;
}

.hubmanager_round_corner_background {
  background-color: white;
  border-radius: 0px 130px 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: 86vh;
  z-index: 1;
  padding-bottom: 60px;
}

.hubmanager_title {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  margin: 20px 0;
  font-weight: bold;
  padding-left: 10px;
}

.hubmanager_tables_container {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  flex-wrap: wrap;
}

.hubmanager_table_item {
  position: relative;
  text-align: center;
  color: white;
  margin: 20px;
}

.hubmanager_table_icon {
  width: 20vw;
  min-width: 150px;
}

.hubmanager_table_icon.hmimg0 {
  filter: brightness(0.5) sepia(1) saturate(10000%);
}
.hubmanager_table_icon.hmimg1 {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(120deg);
}
.hubmanager_table_icon.hmimg2 {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(260deg);
}
.hubmanager_table_icon.hmimg3 {
  filter: brightness(0.5) sepia(1) saturate(5000%) hue-rotate(240deg);
}
.hubmanager_table_icon.hmimg4 {
  filter: brightness(0.5) sepia(1) saturate(10000%);
}
.hubmanager_table_icon.hmimg5 {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(120deg);
}
.hubmanager_table_icon.hmimg6 {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(260deg);
}
.hubmanager_table_icon.hmimg7 {
  filter: brightness(0.5) sepia(1) saturate(5000%) hue-rotate(240deg);
}
.hubmanager_table_icon.hmimg8 {
  filter: brightness(0.5) sepia(1) saturate(10000%);
}
.hubmanager_table_icon.hmimg9 {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(120deg);
}
.hubmanager_table_icon.hmimg10 {
  filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(260deg);
}

.hubmanager_table_name {
  position: absolute;
  top: 3px;
  left: 8px;
}

.hubmanager_table_name:first-letter {
  text-transform: uppercase;
}
