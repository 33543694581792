/* ReceiptSingle.css */

.ReceiptSingle {
    position: absolute;
}

.rs_round_corner_background {
    background-color: white;
    border-radius: 0px 130px 0px 0px;
    position: absolute;
    top: 127px;
    left: 0;
    width: 100vw;
    z-index: 1;
    padding-bottom: 60px;
    min-height: 86vh;
}

.rs_top_img_wrapper {
    width: 100vw;
    background-image: url("/assets/images/calculator_img.jpg");
    background-repeat: no-repeat;
    background-size: 127%;
    background-position: -26px -102px;
    height: 290px;
}

.rs_top_back_arrow {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 12px;
}

#rs_next_receipt_btn {
    top: 100px;
    position: absolute;
    left: 7px;
    z-index: 800;
}
.form-container{
    overflow: scroll;
    position: absolute;
    margin: -60px 0 60px 0;
    width: 100vw;
    z-index: 1;
    height: 100%;
}

#rs_prev_receipt_btn {
top: 100px;
position: absolute;
right: 7px;
z-index: 800;
}
.swing-in-right-fwd {
animation: swing-in-right-fwd 0.5s ease-in both;
}
@keyframes swing-in-right-fwd {
0% {
    transform: translateX(-1000px);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
}

.swing-in-left-fwd {
animation: swing-in-left-fwd 0.5s ease-in both;
}
@keyframes swing-in-left-fwd {
0% {
    transform: translateX(1000px);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
}
.swing-out-left-bck {
animation: swing-out-left-bck 0.5s ease-in both;
}
@keyframes swing-out-left-bck {
0% {
    transform: rotateY(0);
    transform-origin: left;
}
100% {
    transform: rotateY(90deg);
    transform-origin: left;
}
}
.swing-out-right-bck {
animation: swing-out-right-bck 0.5s ease-in both;
}
@keyframes swing-out-right-bck {
0% {
    transform: rotateY(0);
    transform-origin: right;
}
100% {
    transform: rotateY(90deg);
    transform-origin: right;
}
}

#receipt_form2 {
position: absolute;
left: 11%;
    margin-bottom: 60px;
}

.rs_btn_disabled {
filter: opacity(25%);
}

.rs_receipt_count_wrapper {
top: 175px;
position: absolute;
right: 0;
display: flex;
justify-content: center;
flex-direction: column;
width: 48px;
}

.rs_receipt_count_wrapper > span {
align-self: center;
}

.rs_receipt_count_wrapper > span:first-of-type {
font-weight: bold;
}

.rs_blank_receipt_container {
background-color: white;
width: 300px;
box-sizing: border-box;
font-family: "Oxygen Mono", monospace;
font-size: 19px;
padding: 20px;
margin: 5px auto;
border: 1px solid lightgrey;
box-shadow: 5px 5px 5px grey;
letter-spacing: -1px;
position: relative;
top: -72px;
}
