.backup_page_root {
  position: relative;
}

.backup_page_bg {
  position: absolute;
  filter: hue-rotate(210deg);
  opacity: 0.15;
  width: 100vw;
}

.backup_page_container {
  position: relative;
  width: 100%;
  padding: 0 20px;
}

.backup_page_back_button {
  position: absolute;
  height: 19px;
  width: fit-content;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 15px;
  z-index: 20;
  top: 10px;
  left: 10px;
}

.backup_page_title_text {
  padding-top: 8px;
  text-align: center;
  width: 100%;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
}

.backup_page_history_container {
  margin: 20px 10px;
}

.backup_page_history_text {
  color: #96969a;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.backup_page_history_text > span {
  font-weight: bold;
}

.backup_page_block {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
  padding: 15px;
  margin-bottom: 20px;
}

.backup_page_block_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.backup_page_block_top_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .backup_page_block_top_right {
} */

.backup_page_info_checkbox_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.backup_page_download_btn_wrap {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-shrink: 0;
  cursor: pointer;
}

.backup_page_subtitle {
  color: #232326;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 20px;
  padding: 0 20px;
}

.backup_page_info_btn_wrap {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: white;
  background-color: #3fa4ff;
  position: relative;
}

.backup_page_info_btn_close {
  position: absolute;
  transition: all 0.5s ease !important;
  cursor: pointer;
}

.backup_page_info_btn_show {
  position: absolute;
  transition: all 0.5s ease !important;
  cursor: pointer;
}

.bpibhide {
  opacity: 0;
}

.backup_page_info_checkbox_title {
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.63px;
  line-height: 18px;
}

.backup_page_info_checkbox {
  margin: 10px;
}

.backup_page_block_bottom {
  color: #96969a;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease;
}

.backup_page_block_bottom > span > strong {
  color: #6d6c6c;
  font-weight: 800;
}

.backup_page_block_bottom.bpibexpand {
  max-height: 500px;
}

@media all and (max-width: 550px) {
  .backup_page_block_top_right {
    margin-top: 10px;
  }

  .backup_page_title_text {
    padding-top: 40px;
  }
}
