.contacts_container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.contact_card{
    margin: 2.5%;
    min-height: 100px;
    background-color: aliceblue;
    border: 1px solid #E4E4E4;
    box-shadow: 0 2px 18px -4px rgba(0,0,0,0.14);
    border-radius: 7px;
    padding: 5px;
    
}

@media (min-width: 601px){
    .contact_card{
        width: 45%;
    }
 
}

@media (max-width: 600px){
    .contact_card{
        width: 95%;
    }
 
}

.contact_title{
    font-size: 20px;
    font-weight: bold;
}
.contact_info{
    font-size: 15px;
}
