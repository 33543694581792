/* login.css */

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

.login_page_block {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.login_page_bg {
  opacity: 0.1;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 15%;
  filter: hue-rotate(215deg);
}
.login_page_content {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 501px) {
  .login_page_content {
    top: 15vh;
  }
}
@media (max-width: 500px) {
  .login_page_content {
    top: 5vh;
  }
}
.login_page_title {
  color: #232326;
  font-family: Roboto;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.88px;
  line-height: 33px;
  margin-bottom: 20px;
}
.login_form_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_form_title {
  text-align: center;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
  margin-bottom: 10px;
}
.login_form {
  width: 247px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 5px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MuiTabs-indicator {
  background-color: #3fa4ff !important;
}
.MuiTab-root {
  padding: 0 !important;
  bottom: -10px;
  min-width: 0px !important;
}

.Mui-selected {
  color: #3fa4ff !important;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 165px;
  margin: 5px auto 0px;
  color: #545669;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
.login_form_input {
  border: 1px solid #676767;
  border-radius: 7px;
  height: 34px;
  padding-left: 10px;
  margin-top: 3px;
}

.login_form_input::placeholder {
  font-style: italic;
}

.form_submit_btn {
  height: 32px;
  width: 164px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 20px auto;
}

.form_cancel_btn {
  height: 32px;
  width: 164px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 20px auto;
}
.personal_hub_block_title {
  margin-top: 30px;
  margin-bottom: 15px;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
  text-align: center;
}
.personal_hub_form {
  width: 247px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
.personal_hub_title {
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
  margin-bottom: 10px;
}
.personal_hub_form_text {
  color: #545669;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.form_small_title {
  margin-top: 10px;
}

#login_error {
  color: #ff0000;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
}

#login_login_validation_error {
  color: #ff0000;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
}
