.admin_container {
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.close_icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
}
.login_btn {
  width: 117px;
  border: 1px solid #aac8ff !important;
  border-radius: 4px !important;
  background-color: #3fa5ff !important;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2) !important;
  margin-top: 15px !important;
  color: #fff !important;
  margin-bottom: 15px !important;
}
.admin_block {
  position: relative;
  min-width: 300px;
  max-width: 95vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 0 20px 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin_block_title {
  margin-top: 25px;
  max-width: 200px;
  text-align: center;
}
.admin_block_link {
  color: #3fa4ff;
  font-weight: bold;
}
.admin_block_subtitle {
  margin: 20px 0 20px 0;
}
.password_field_block {
  display: flex;
  align-items: center;
  max-width: 200px;
}
.password_input_container {
  display: flex;
  justify-content: center;
}
.password_input_wrapper {
  display: flex;
  flex-direction: column;
}
.stepper_content {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email_input_wrapper {
  width: 60vw;
  max-width: 400px;
  min-width: 250px;
}
.admin_input {
  border: 1px solid #676767;
  border-radius: 7px;
  padding-left: 5px;
  margin-top: 3px;
  width: 100%;
  height: 35px;
  margin-right: 10px;
}
.admin_input::placeholder {
  color: #b2b2b2;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}
.input_title {
  color: #545669;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 15px;
}
.admin_stepper_text {
  color: #545669;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
}
.admin_stepper_finish_text {
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
}
.next_btn {
  width: 117px;
  border: 1px solid #aac8ff !important;
  border-radius: 4px !important;
  background-color: #3fa5ff !important;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2) !important;
  margin-top: 15px !important;
  color: #fff !important;
}
.MuiStepIcon-root {
  color: #fff !important;
  border: 2px solid #3fa4ff !important;
  border-radius: 50% !important;
}
.MuiStepIcon-text {
  fill: #3fa4ff !important;
}
.MuiStepIcon-active {
  color: #3fa4ff !important;
}
.MuiStepConnector-line {
  border-color: #3fa4ff !important;
}
