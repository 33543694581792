/* GENERAL */
* {
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  position: relative;
}
header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
}
.header_date_picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* GRID */
.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.row-middle {
  align-items: center;
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

/* Calendar */
.calendar_container {
  width: 95%;
  margin: 10px auto;
  background-color: #fff;
  height: fit-content;
}

.filters_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.filters_container ul {
  padding-left: 0;
  width: 100%;
}
.filters_container ul li:nth-child(odd) {
  background-color: #f2f5fa;
}
.filters_container_btn{
  width: 270px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filters_btn {
  border: 1px solid #aac8ff !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2) !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 45px !important;
  width: 45px !important;
  min-width: 45px !important;
}

.filters_btn img {
  height: 23px !important;
  width: 23px !important;
}
.filters_item {
  width: auto;
}
.calendar {
  display: block;
  position: relative;
  width: 100%;
  /* background: white; */
  /* border: 1px solid lightgray; */
  height: auto;
  margin-top: 50px;
}
.calendar .header {
  transition: 0.5s all;
  font-size: 115%;
  display: flex;
  align-items: center;
  background: transparent;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.calendar .header input {
  font-weight: 700;
}
.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}
.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: dodgerblue;
}
.calendar .header .icon:first-of-type {
  margin-left: 1em;
}
.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .body .cell {
  position: relative;
  height: 100%;
  padding-top: 25px;
  min-height: 100px;
  border: 1px solid rgba(84, 86, 105, 0.16);
  /* overflow: hidden; */
  cursor: pointer;
  background: white;
  transition: 0.25s ease-out;
  border-radius: 4px;
  box-sizing: border-box;
}

.calendar .body .cell:nth-child(6n) {
  background-color: #f7f9fa;
}
.calendar .body .cell:nth-child(7n) {
  background-color: #f7f9fa;
}
.calendar .body .cell:hover {
  z-index: 1000;
  background: white;
  transition: 0.5s ease-out;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.23);
  border: 1px solid #3fa4ff;
}
.calendar .body .selected {
  border: none;
}
.calendar .body .row {
  /* border-bottom: 1px solid lightgray; */
  width: 100%;
  display: flex;
}

.calendar_week_summary {
  height: fit-content;
  max-height: 130px;
  border: 1px solid #dcdde1;
  border-radius: 8px;
  background-color: #f2f5fa;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
  margin-left: 5px;
  overflow: auto;
  padding: 5px;
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
}
.calendar_week_summary::placeholder {
  position: relative;
  text-align: center;
}
.month_summary_content::placeholder {
  position: relative;
  text-align: center;
}
.month_summary_content:focus {
  border: 1px solid #3fa4ff;
}
.calendar_week_summary:focus {
  border: 1px solid #3fa4ff;
}
.calendar_week_summary.month {
  width: 12.5%;
}
.calendar_week_summary.week {
  width: 100%;
}
.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}
.cell_item {
  font-size: 13px;
}
.cell_item .MuiFormControl-root {
  margin: 7px 0 0 0 !important;
}
.calendar .body .disabled {
  color: lightgray;
  pointer-events: none;
}
.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: black;
  opacity: 0;
  font-size: 5em;
  position: absolute;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.2;
  transition: 0.5s ease-in;
}
.calendar .body .column {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
  overflow: auto;
}
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  /* border: none !important; */
  border-radius: 2px;
}

.MuiFormLabel-root.Mui-disabled {
  color: black !important;
}
.days_names_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.days_names_item {
  text-align: center;
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.44px;
  line-height: 16px;
}
.days_names_item.month {
  width: 12.5%;
}

.days_names_item.week {
  width: 50%;
}

.month_summary {
  display: flex;
  /* margin-top: -15px; */
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.month_summary_title {
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 16px;
}
.month_summary_content {
  max-height: 46px;
  height: fit-content;
  min-width: 120px;
  overflow: auto;
  border: 1px solid #dcdde1;
  border-radius: 8px;
  background-color: #f2f5fa;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  padding: 5px;
  max-width: 400px;
}
.cell_item .MuiInputBase-input.Mui-disabled {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  color: black;
}
.cell_item .MuiOutlinedInput-multiline {
  padding: 2px 0 0 0 !important;
}
.cell_item .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(5px, -6px) scale(0.75) !important;
  white-space: nowrap;
}

.cell_item .MuiOutlinedInput-notchedOutline {
  padding-left: 0px !important;
}

.header_date_picker .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.header_date_picker .MuiInputBase-input {
  width: 80px !important;
  text-align: center;
  margin-right: 5px;
}

.calendar_drawer_title {
  height: 70px;
  min-width: 320px;
  padding-top: 30px;
  color: #232326;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.69px;
  line-height: 25px;
  text-align: center;
}
.calendar_drawer_inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.calendar_drawer_inputs .filter_name {
  border: 1px solid #e4e4e4;
  width: 200px;
  height: 35px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
}

.calendar_drawer_inputs .filter_type {
  border: 1px solid #e4e4e4;
  width: 27%;
  height: 35px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
}
.change_view_btn {
  border: 1px solid #aac8ff !important;
  border-radius: 4px !important;
  background-color: #3fa5ff !important;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2) !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 45px !important;
  width: 45px !important;
  min-width: 45px !important;
  color: white;
}

.day_line {
  overflow: auto;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  background-color: #ffffff;
  width: 49%;
  margin: 0 0.5%;
}

@media (min-width: 768px) {
  .day_line {
    /* max-width: 216px; */
    width: 32.3% !important;
  }
}

@media (min-width: 1025px) {
  .day_line {
    /* max-width: 216px; */
    width: 24% !important;
  }
}

.day_line .number {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #3fa5ff;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.day_line .content {
  margin-top: 7px;
}
.day_view {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  height: fit-content;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  background-color: #ffffff;
}

.day_view .number {
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: fit-content;
  position: relative;
  text-align: center;
  background-color: #3fa4ff;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.day_view .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 5px;
  height: fit-content;
  justify-content: space-around;
}
.view_toggle_buttons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view_toggle_buttons .MuiToggleButtonGroup-root {
  background-color: transparent !important;
}
.view_toggle_buttons
  .MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped {
  background-color: transparent !important;
  margin: 0 5px 0 5px;
}

.week_view_summary {
  display: flex;
  flex-direction: column;
  width: 49%;
  height: fit-content;
  overflow: auto;
  margin: 0 0.5%;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .week_view_summary {
    width: 32.3% !important;
  }
}

@media (min-width: 1025px) {
  .week_view_summary {
    width: 24% !important;
  }
}

.week_view_summary_title {
  width: 100%;
  height: fit-content;
  background-color: #3bbbff;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.week_view_summary_content {
  padding: 5px;
}

.capitalized_title > label:first-letter {
  text-transform: uppercase;
}
.capitalized_filter > span:first-letter {
  text-transform: uppercase;
}
