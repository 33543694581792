.info_modal_block{
    background-color: #fff;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    overflow: auto;
    margin: 15% 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px;
    border-radius: 8px;
    position: relative;
}
.info_modal_close_icon{
    position: absolute;
    top: 3px;
    right: 3px;
}
.info_modal_block img{
    width: 70px;
}
.info_modal_content{
    height: 100%;
    width: 75%;
    font-family: Roboto;
}
.info_modal_content_title{
    font-size: 18px;
    font-weight: bold;
}
.info_modal_content_subtitle{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
}
.info_modal_content_p{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}
.info_modal_content_footnote{
    font-size: 12px;
}