/* CovidStatistics.css */

.cov19_stat_container {
  width: 100%;
  padding: 20px;
}

.cov19_stat_graphs_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.cov19_stat_grapth_box {
  width: calc(50% - 10px);
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cov19_stat_grapth_top {
  display: flex;
  position: relative;
}

.cov19_stat_grapth_top_text {
  flex-shrink: 0;
  color: #545669;
  font-family: Roboto;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.cov19_stat_grapth_top_text > span {
  font-weight: 800;
}

.cov19_stat_grapth_middle {
  margin: 10px 0;
  color: #545669;
  font-family: Roboto;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.cov19_stat_grapth_my_answr {
  color: #545669;
  font-family: Roboto;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.cov19_stat_grapth_bottom {
  position: relative;
}

@media all and (max-width: 600px) {
  .cov19_stat_grapth_box {
    width: 100%;
  }
}

.cov19_stat_header_wrapper {
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.cov19_stat_header_flex_box {
  display: flex;
}

.cov19_stat_header_title {
  color: #232326;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 28px;
}

.cov19_stat_header_text {
  color: #b6bdc3;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.cov19_stat_header_update_time_wrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cov19_stat_header_update_time {
  color: #545669;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.cov19_stat_header_update_time_label {
  color: #545669;
  font-family: Roboto;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: 5px;
}

@media all and (max-width: 700px) {
  .cov19_stat_header_wrapper {
    flex-wrap: wrap;
  }

  .cov19_stat_header_update_time_wrapper {
    margin: 10px 10px 0 0;
  }
}

@media all and (max-width: 410px) {
  .cov19_stat_header_title {
    font-size: 18px;
  }

  .cov19_stat_header_text {
    font-size: 14px;
  }
  .cov19_stat_header_update_time {
    font-size: 14px;
  }
  .cov19_stat_header_update_time_label {
    font-size: 12px;
  }
}

.cov19_stat_top {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.cov19_stat_top_home_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  padding: 8px 31px;
}

.cov19_stat_top_home_text {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.covid19_red {
  background-color: #eb6565;
}
.covid19_blue {
  background-color: #1c93ff;
}
.covid19_green {
  background-color: #45e141;
}
.covid19_gray {
  background-color: #9e9e9e;
}

.cov19_pool_option {
  margin-left: 10px;
  height: 31px;
  width: 31px;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cov_stat_modes_container {
  margin-bottom: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.cov_stat_modes_arrow_box {
  height: 52px;
  width: 53px;
  border: 1px solid #3fa4ff;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3fa4ff;
}

.cov_stat_modes_mode_title {
  color: #232326;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 21px;
  text-align: center;
  padding-bottom: 10px;
}

.cov_stat_small_dot_wrapper {
  display: flex;
  justify-content: center;
}

.cov_stat_small_dot {
  height: 6px;
  width: 6px;
  border: 1px solid #3fa4ff;
  border-radius: 50%;
  margin: 0 5px;
}

.cov_stat_small_dot_filled {
  height: 6px;
  width: 6px;
  background-color: #3fa4ff;
  margin: 0 5px;
  border-radius: 50%;
}
