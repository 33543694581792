/* avatar.css */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

.central_pic {
  height: 80%;
  display: flex;
  justify-self: center;
  align-self: center;
  width: 80%;
  border-radius: 50%;
  grid-column: 1;
  grid-row: 1;
}

.central_pic_ring {
  height: 100%;
  display: flex;
  justify-self: center;
  align-self: center;
  width: 100%;
  border-radius: 50%;
  grid-column: 1;
  grid-row: 1;
}

#central_avatar_container {
  position: absolute;
  left: 41.5vw;
  top: calc(50vh - 8.5vw);
}

#central_avatar_wrapper {
  height: 17vw;
  width: 17vw;
  display: grid;
}

/* rename class to something else because conflict with bootstrap css class name and breaking layout */

.avatar_settings {
  position: absolute;
  right: 0;
  bottom: 0;
}

#edit_pencil {
  max-width: 25px;
  height: auto;
  width: 2vw;
}

#avatar_grid_container {
  display: grid;
  grid-template-areas: "left center right";
  grid-template-columns: 40% 25% 35%;
}

.avatar_grid_right_column_grid_container {
  grid-area: right;
  display: grid;
  grid-template-areas:
    "right1"
    "right2"
    "right3"
    "right4"
    "right5";
  grid-template-columns: 100%;
}

.set_avatar_text {
  grid-area: right1;
}

#avatar_carousel {
  grid-area: right2;
}

#avatar_carousel > span > img {
  width: 30%;
}

#avatar_editor_preview {
  display: grid;
  grid-template-areas:
    "left1 left1"
    "left2 left3";
}

.avatar_upload_text {
  grid-area: right3;
  widows: 100%;
}

.avatar_editor_selectfile {
  grid-area: right4;
  widows: 100%;
}

.avatar_editor_selectfile > input {
  overflow: hidden;
  width: 100%;
}

.avatar_editor_zoom {
  grid-area: left2;
  margin-top: 10px;
}

.avatar_editor_rotate {
  grid-area: left3;
  margin-top: 8px;
}

.avatar_editor_rotate > button {
  padding: 0 15px;
}

.avatar_editor_submit {
  grid-area: right5;
}

/* avatar info */
.Avatar{
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

#AvatarInfo {
  position: fixed;
  bottom: 60px;
  right: 0;
  height: 400px;
  width: 300px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  z-index: 5;

  animation: AvatarInfo_entrance 0.5s 1;
  animation-timing-function: ease;
}

@keyframes AvatarInfo_entrance {
  from {
    opacity: 0;
    height: 100px;
  }
  to {
    opacity: 1;
    height: 400px;
  }
}

.avat_close_info {
  transform: rotate(-90deg);
  height: 25px;
  align-self: center;
  margin-top: 15px;
}

.avat_content_wrapper {
  padding: 5px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  font-family: "Ubuntu", sans-serif;
}

/* .avat_hubid_pin {
  margin-bottom: 20px;
} */

.avat_barcode_img {
  align-self: center;
  border: 1px solid black;
  padding: 0 20px;
}
