.subcategory_container{
    min-height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    width: fit-content;
    padding-top: 20px;
}
.subcategory_item{
    transition-property: transform box-shadow;
  transition-duration: 0.3s;
    position: relative;
    width: 60px;
    height: 60px;
    margin: 0 5px 0 5px;
    border-radius: 4px;
    border-right: 1px solid grey;
}
.subcategory_title{
  font-size: 12px;
  text-align: center;
  min-height: 20px;
  font-weight: bold;
  margin-top: -5px;
}