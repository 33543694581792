.user_info_block {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.edit_profile_user_info_item {
  width: 49%;
  margin-bottom: 15px;
  min-width: 215px;
}
.edit_profile_user_info_item .MuiInputBase-input.Mui-disabled {
  /* padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important; */
  color: black;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.edit_profile_user_info_item
  .MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  /* border: 1px solid #e4e4e4 !important; */
  border-radius: 7px !important;
}
.user_info_language legend {
  width: 64px !important;
}

.edit_profile_avatar_container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 5px;
}

.update_successfull {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: green;
  border: 1px solid green;
  border-radius: 5px;
}

.update_error {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
}

.ep_backup_cont {
  display: flex;
  flex-direction: column;
}

.ep_backup_title_wrap {
  display: flex;
  justify-content: center;
}

.ep_backup_info_block {
  overflow: hidden;
  height: auto;
  max-height: 300px;
  transition: all 1s ease;
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.ep_backup_info_block.hidden {
  max-height: 0px;
}

.ep_backup_nav_wrap {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ep_backup_nav_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 20px;
}

.ep_backup_info_btn {
  color: #3fa5ff;
  font-size: 30px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
