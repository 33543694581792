/*------------------------------------------------------------------

    [SHOP MUBUBU CSS]

    Theme Name: general, grid layout customizable for any shop
    Version: 1.0
    Last change: Apr/2019

-------------------------------------------------------------------*/

/*------------------------------------------------------------------

    [Table of contents]

    0.0 CONTENT
    1.0 GENERAL
	  2.0 Receipt
	  3.0 Navigation
	  4.0 Modal div

-------------------------------------------------------------------*/

/* -----------------------------------------------------------------

                         0.0 CONTENT

------------------------------------------------------------------*/
.css3receipt_grid_container {
  --header_line1: "";
  --header_line2: "Müller Büro & Buch";
  --header_line3: "Lottoannahmestelle";
  --header_line4: "56288 Kastellaun * Bahnhofstr, 16";
  --header_line5: "Fon: 06762/ 969900-0 Fax: -969900-9";
  --header_line6: "em@il: info@MuBuBu.de";
  --header_line7: "**Vorausrechnung - digitale Kopie**";
  --header_line8: "Datum/Zeit";
  --header_line9: "Kasse/Bon";
  --header_line10: "Kassierer";
  --cashier_operator_descr: "";
  --receipt_date_desc: "";
  --receipt_time_desc: "";
  --receipt_ID_desc: "";
  --receipt_NUM_desc: "/";
  --shop_location_desc: "";
  --shop_cashier_num_desc: "";
  --shop_goods_desc: "Artikel";
  --shop_goods_amount_desc: "Mg.";
  --shop_unit_price_desc: "Preis";
  --shop_price_and_currency_desc: "Summe";
  --shop_tax_desc: "MwSt.";
  --receipt_items_count_desc: "Anz. Artikel";
  --item_total_price_sign: "";
  --item_multiplication_sign: "x";
  --total_sum_desc: "Summe";
  --tax_base_desc: "";
  --tax_name_desc: "";
  --tax_sum_desc: "";
  --rounding_desc: "TOTAL";
  --cash_sum_taken_desc: "Bar";
  --cash_to_return_desc: "Rückgeld EUR";
  --footer_receipt_num_desc: "";
  --article_group_title: "WGR";
  --sum_to_tax: "";
  --tax_base_sum: "Nettobetrag";
  --tax_sum: "MwSt";
  --for_tax_combined_desc: "MwSt-Bruttoumsatz";
  --tax_base_combined_desc: "";
  --tax_combined_desc: "";
  --cashier_identifier_1_desc: "StNr. 40/189/3008/5";
  --cashier_identifier_2_desc: "";
  --cashier_identifier_3_desc: "";
  --payment_method: "";
  --footer_line_1: "";
  --footer_line_2: "Vielen Dank für Ihren Einkauf!";
  --footer_line_3: "Besuchen Sie uns doch auch im";
  --footer_line_4: "Internet auf www.MuBuBu.de";
  --footer_line_5: "Dieser Bon ist kein Steuerbeleg und ist nicht rechtsverbindlich; maßgeblich ist allein das Original ihrer Rechnung.";
  --footer_line_6: "";
  --footer_line_7: "";
}

#css3HEADER_LINE_1::before {
  content: var(--header_line1);
}

#css3HEADER_LINE_2::before {
  content: var(--header_line2);
}

#css3HEADER_LINE_3::before {
  content: var(--header_line3);
}

#css3HEADER_LINE_4::before {
  content: var(--header_line4);
}

#css3HEADER_LINE_5::before {
  content: var(--header_line5);
}

#css3HEADER_LINE_6::before {
  content: var(--header_line6);
}

#css3HEADER_LINE_7::before {
  content: var(--header_line7);
}

#css3HEADER_LINE_8::before {
  content: var(--header_line8);
}

#css3HEADER_LINE_9::before {
  content: var(--header_line9);
}

#css3HEADER_LINE_10::before {
  content: var(--header_line10);
}

#css3HEADER_CASHIER_OPERATOR::before {
  content: var(--cashier_operator_descr);
}

/* #css3HEADER_DATE::before {
  content: var(--receipt_date_desc);
} */

/* #css3HEADER_TIME::before {
  content: var(--receipt_time_desc);
} */

/* #css3HEADER_RECEIPT_ID::before {
  content: var(--receipt_ID_desc);
} */

#css3HEADER_RECEIPT_NUM_1::before {
  content: var(--receipt_NUM_desc);
  margin-right: 5px;
}

/* #css3HEADER_LOCATION_1::before {
  content: var(--shop_location_desc);
} */

#css3HEADER_CASHIER_NUM_1::before {
  content: var(--shop_cashier_num_desc);
}

#css3SHOP_GOODS_NAME::before {
  content: var(--shop_goods_desc);
}

#css3SHOP_GOODS_AMOUNT::before {
  content: var(--shop_goods_amount_desc);
}

#css3SHOP_UNIT_PRICE::before {
  content: var(--shop_unit_price_desc);
}

#css3SHOP_PRICE_AND_CURRENCY::before {
  content: var(--shop_price_and_currency_desc);
}

#css3SHOP_TAX_DESC::before {
  content: var(--shop_tax_desc);
}

/* .css3total_item_price_incl_tax::before {
  content:  var(--item_total_price_sign);
} */

.css3unit_price_incl_tax::before {
  content: var(--item_multiplication_sign);
  margin-right: 20px;
}

/* .css3item_id_class::before {
  content: var(--article_group_title);
  margin-right: 10px;
} */

#css3FOOTER_SUM_DESC::before {
  content: var(--total_sum_desc);
}

#css3CASH_SUM_TAKEN_DESC::before {
  content: var(--cash_sum_taken_desc);
}

#css3CASH_TO_RETURN_DESC::before {
  content: var(--cash_to_return_desc);
}

#css3FOOTER_RECEIPT_NUM_DESC::before {
  content: var(--footer_receipt_num_desc);
}

#css3BIG_TOTAL_PRICE_DESC::before {
  content: var(--for_tax_combined_desc);
}

#css3SUM_WITHOUT_VAT_BIG_TOTAL_DESC::before {
  content: var(--tax_base_combined_desc);
}

#css3SUM_VAT_BIG_TOTAL_DESC::before {
  content: var(--tax_combined_desc);
}

#css3TAX_BASE_DESC::before {
  content: var(--tax_base_desc);
}

#css3TAX_NAME_DESC::before {
  content: var(--tax_name_desc);
}

#css3TAX_SUM_DESC::before {
  content: var(--tax_sum_desc);
}

#css3ROUNDING_DESC::before {
  content: var(--rounding_desc);
}

#css3ITEMS_COUNT_DESC::before {
  content: var(--receipt_items_count_desc);
}

.css3FOR_TAX_DESC::after {
  content: var(--sum_to_tax);
}

.css3TAX_BASE_DESC::before {
  content: var(--tax_base_sum);
}

.css3TAX_SUM_DESC::before {
  content: var(--tax_sum);
}

#css3CASHIER_IDENTIFIER_1::before {
  content: var(--cashier_identifier_1_desc);
  margin-right: 10px;
}

#css3CASHIER_IDENTIFIER_2::before {
  content: var(--cashier_identifier_2_desc);
}

#css3CASHIER_IDENTIFIER_3::before {
  content: var(--cashier_identifier_3_desc);
}

/* #css3PAYMENT_METHOD::before {
  content: var(--payment_method);
} */

#css3FOOTER_LINE_1::before {
  content: var(--footer_line_1);
}

#css3FOOTER_LINE_2::before {
  content: var(--footer_line_2);
}

#css3FOOTER_LINE_3::before {
  content: var(--footer_line_3);
}

#css3FOOTER_LINE_4::before {
  content: var(--footer_line_4);
}

#css3FOOTER_LINE_5::before {
  content: var(--footer_line_5);
}

#css3FOOTER_LINE_6::before {
  content: var(--footer_line_6);
}

#css3FOOTER_LINE_7::before {
  content: var(--footer_line_7);
}

/* -----------------------------------------------------------------

                         1.0 GENERAL

------------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css?family=Oxygen+Mono&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bai+Jamjuree:600");

/* -----------------------------------------------------------------

                         2.0 Receipt

------------------------------------------------------------------*/

.css3receipt_grid_container {
  display: grid;
  grid-template-areas:
    ". . L0"
    "L1 L1 L1"
    "L2 L2 L2"
    "L3 L3 L3"
    "L4 L4 L4"
    "L5 L5 L5"
    "L6 L6 L6"
    "L7 L7 L7"
    "L8 L8 L8"
    "L9 L9 L9"
    "L10 L10 L10"
    "L11 L11 L11"
    "L12 L12 L12"
    "wrapper wrapper wrapper"
    "TOTAL1 TOTAL2 TOTAL2"
    "L13a L13a L13b"
    "L14a L14a L14b"
    "L15a L15a L15b"
    "L16 L16 L16"
    /*"L16a L16a L16b"
    "L17a L17a L17b"
    "L18a L18a L18b"
    "L19a L19a L19b"
    "LvatCa1 LvatCa1 LvatCb1"
    "LvatCa2 LvatCa2 LvatCb2" */
    "L20a L20a L20b"
    "L21a L21b L21c"
    "L22a L22b L22c"
    "L23 L23 L23"
    "L24 L24 L24"
    "L25 L25 L25"
    "L26 L26 L26"
    "L27 L27 L27"
    "L28 L28 L28"
    "L29 L29 L29";
  grid-template-columns: 25% 50% 25%;
  background-color: white;
  /* width: 420px; */
  width: 330px;
  box-sizing: border-box;
  /* font-family: sans-serif; */
  font-family: "Oxygen Mono", monospace;
  /* font-family: "Courier New", Arial; */
  /* font-size: 19px; */
  font-size: 14px;
  padding: 20px;
  margin: 5px auto;
  border: 10px solid lightgrey;
  /* box-shadow: 5px 5px 5px grey; */
  letter-spacing: -1px;
  border-radius: 10px;
}

/* ============================ */
/* MAIN GRID SPACERS */
/* ============================ */
.css3spacer0 {
  grid-area: L0;
  border: none;
  background: white;
}

.css3spacer0:active, .css3spacer0:focus{
  outline: none;
}

.css3spacer1 {
  display: none;
  grid-area: spacer1;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
}

.css3spacer2 {
  display: none;
  grid-area: spacer2;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
}

.css3spacer3 {
  display: none;
  grid-area: spacer3;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
}

.css3spacer4 {
  display: none;
  grid-area: spacer4;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
}

#css3HEADER_LINE_1 {
  grid-area: L1;
  background-image: url("/assets/images/mububu.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 261px;
}

#css3HEADER_LINE_2 {
  margin-top: 10px;
  grid-area: L2;
  justify-self: center;
}

#css3HEADER_LINE_3 {
  grid-area: L3;
  justify-self: center;
}

#css3HEADER_LINE_4 {
  grid-area: L4;
  justify-self: center;
}

#css3HEADER_LINE_5 {
  grid-area: L5;
  justify-self: center;
}

#css3HEADER_LINE_6 {
  grid-area: L6;
  justify-self: center;
}

#css3HEADER_LINE_7 {
  grid-area: L7;
  justify-self: center;
  margin-top: 40px;
}

#css3HEADER_LINE_8 {
  grid-area: L21b;
  justify-self: center;
}

#css3HEADER_LINE_9 {
  grid-area: L21a;
}

#css3HEADER_LINE_10 {
  grid-area: L21c;
  justify-self: end;
}

#css3HEADER_TIME_HM_DELIM_COL {
  grid-area: L22b;
  display: flex;
  justify-self: end;
  padding-right: 10px;
}

#css3HEADER_TIME_HMS_DELIM_COL {
  display: none;
}

#css3HEADER_DATE_DELIM_DOT {
  grid-area: L22b;
  padding-left: 10px;
}

#css3HEADER_DATE_DELIM_HYPHEN {
  display: none;
}

#css3HEADER_DAY_NAME {
  display: none;
}

#css3HEADER_CASHIER_OPERATOR {
  grid-area: L22c;
  justify-self: end;
}

#css3HEADER_RECEIPT_ID {
  display: none;
}

#css3HEADER_RECEIPT_NUM_1 {
  grid-area: L22a;
  display: flex;
  justify-self: end;
  margin-right: 21px;
}

#css3HEADER_RECEIPT_NUM_2 {
  display: none;
}

#css3HEADER_LOCATION_1 {
  display: none;
}

#css3HEADER_LOCATION_2 {
  display: none;
}

#css3HEADER_CASHIER_NUM_1 {
  grid-area: L22a;
}

#css3HEADER_CASHIER_NUM_2 {
  display: none;
}

/* ============================ */
/* CONTENT GRID CONTAINTER */
/* ============================ */

.css3receipt_content {
  display: none;
}

#css3SHOP_GOODS_NAME {
  display: none;
}

#css3SHOP_GOODS_AMOUNT {
  display: none;
}

#css3SHOP_UNIT_PRICE {
  display: none;
}

#css3SHOP_TAX_DESC {
  display: none;
}

#css3SHOP_PRICE_AND_CURRENCY {
  display: none;
}

.css3item_wrapper {
  grid-area: wrapper;
  margin-top: 20px;
}

.css3item_inner_wrapper_grid {
  display: grid;
  grid-template-areas:
    "a a"
    "b b"
    "c c"
    "d e";
  grid-template-columns: 95% 5%;
}

.css3single_item {
  display: none;
}

.css3item_description_class {
  grid-area: b;
  overflow: hidden;
  height: 1.5em;
}

.css3item_id_class {
  display: none;
}

.css3item_ean_class {
  display: none;
}

.css3item_free_parameter1_class {
  grid-area: c;
}

.css3item_amount {
  grid-area: a;
  margin-left: 20px;
}

.css3item_amount .single_item {
  display: none;
}

.css3item_measure_unit {
  display: none;
}

.css3unit_price_incl_tax {
  grid-area: a;
  margin-left: 60px;
}

.css3total_item_price_incl_tax {
  grid-area: d;
  display: flex;
  justify-self: end;
}

.css3tax_group_descr {
  grid-area: e;
  display: flex;
  justify-self: end;
}

.css3tax_group_value {
  display: none;
}

#css3ITEMS_COUNT_DESC {
  grid-area: L20a;
  margin: 20px 0;
}

#css3ITEMS_COUNT_NO_DECIMALS {
  grid-area: L20b;
  display: flex;
  justify-self: end;
  margin: 20px 0;
  margin-right: 70px;
}

#css3ITEMS_COUNT_ONE_DECIMAL {
  display: none;
}

#css3ITEMS_COUNT_TWO_DECIMAL {
  display: none;
}

#css3ROUNDING_DESC {
  display: none;
}

#css3ROUNDING {
  display: none;
}

#css3TAX_BASE_DESC {
  display: none;
}

#css3TAX_NAME_DESC {
  display: none;
}

#css3TAX_SUM_DESC {
  display: none;
}

.css3vat_grid_wrapper {
  grid-area: L16;
}

.css3vat_grid_container {
  display: grid;
  grid-template-areas:
    "L1a L1b"
    "L2a L2b";
  display: none;
}

.css3VAT_RATE {
  grid-area: L1a;
  display: none;
}

.css3VAT_CATEGORY_ROW1 {
  grid-area: L1a;
  margin-left: 125px;
  display: none;
}
.css3VAT_CATEGORY_ROW2 {
  display: none;
}
.css3VAT_CATEGORY_ROW3 {
  display: none;
}

.css3FOR_TAX_DESC {
  display: none;
}

.css3FOR_TAX_SUM {
  display: none;
}

.css3TAX_BASE_DESC {
  grid-area: L2a;
  display: none;
}

.css3TAX_BASE_SUM {
  grid-area: L2b;
  display: flex;
  justify-self: end;
  display: none;
}

.css3TAX_SUM_DESC {
  grid-area: L1a;
  margin-left: 75px;
  display: none;
}

.css3TAX_SUM {
  grid-area: L1b;
  display: flex;
  justify-self: end;
  display: none;
}

#css3BIG_TOTAL_PRICE_DESC {
  grid-area: L15a;
  display: none;
}

#css3BIG_TOTAL_PRICE {
  grid-area: L15b;
  display: flex;
  justify-self: end;
  display: none;
}

#css3SUM_WITHOUT_VAT_BIG_TOTAL_DESC {
  display: none;
}

#css3SUM_WITHOUT_VAT_BIG_TOTAL {
  display: none;
}

#css3SUM_VAT_BIG_TOTAL_DESC {
  display: none;
}

#css3SUM_VAT_BIG_TOTAL {
  display: none;
}

#css3FOOTER_SUM_DESC {
  grid-area: TOTAL1;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css3FOOTER_SUM_DESC::first-letter {
  display: none;
}

#css3CART_TOTAL_PRICE {
  grid-area: TOTAL2;
  display: flex;
  justify-self: end;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 25px;
}

#css3CASH_SUM_TAKEN_DESC {
  grid-area: L13a;
}

#css3CASH_SUM_TAKEN_DESC::first-letter {
  display: none;
}

#css3CASH_SUM_TAKEN {
  grid-area: L13b;
  display: flex;
  justify-self: end;
}

#css3CASH_TO_RETURN_DESC {
  grid-area: L14a;
}

#css3CASH_TO_RETURN_DESC::first-letter {
  display: none;
}

#css3CASH_TO_RETURN {
  grid-area: L14b;
  display: flex;
  justify-self: end;
}

.css3CASH_TO_RETURN_ZERO {
  color: transparent !important;
}

#css3FOOTER_RECEIPT_NUM_DESC {
  display: none;
}

#css3FOOTER_RECEIPT_NUM {
  display: none;
}

#css3CASHIER_IDENTIFIER_1 {
  grid-area: L28;
  display: flex;
  justify-self: center;
  margin-bottom: 20px;
}

#css3CASHIER_IDENTIFIER_2 {
  display: none;
}

#css3CASHIER_IDENTIFIER_3 {
  display: none;
}

#css3PAYMENT_METHOD {
  display: none;
}

#css3PAYMENT_REGISTRATION_TYPE {
  display: none;
}

#css3FOOTER_LINE_1 {
  display: none;
}

#css3FOOTER_LINE_2 {
  grid-area: L25;
  display: flex;
  justify-self: center;
  margin-top: 50px;
}

#css3FOOTER_LINE_3 {
  grid-area: L26;
  display: flex;
  justify-self: center;
}

#css3FOOTER_LINE_4 {
  grid-area: L27;
  display: flex;
  justify-self: center;
}

#css3FOOTER_LINE_5 {
  margin-top: 10px;
  grid-area: L29;
  display: flex;
  justify-self: center;
  text-align: center;
}

#css3FOOTER_LINE_6 {
  display: none;
}

#css3FOOTER_LINE_7 {
  display: none;
}

/* -----------------------------------------------------------------

                         3.0 Navigation

------------------------------------------------------------------*/

/* -----------------------------------------------------------------

                         MEDIA QUERIES

------------------------------------------------------------------*/

@media screen and (max-width: 375px) {
  .css3receipt_grid_container {
    width: 290px;
    font-size: 12px;
  }

  #css3HEADER_LINE_1 {
    height: 160px;
  }

  #css3HEADER_LINE_7 {
    margin-top: 18px;
  }

  #css3CART_TOTAL_PRICE {
    margin-bottom: 5px;
  }

  #css3ITEMS_COUNT_DESC {
    margin: 0px 0;
  }

  #css3ITEMS_COUNT_NO_DECIMALS {
    margin: 0px 0;
  }

  #css3FOOTER_LINE_2 {
    margin-top: 25px;
  }
}

@media screen and (max-width: 330px) {
  .css3receipt_grid_container {
    width: 240px;
    font-size: 10px;
  }

  #css3HEADER_LINE_1 {
    height: 125px;
  }

  #css3FOOTER_SUM_DESC {
    font-size: 20px;
  }

  #css3CART_TOTAL_PRICE {
    margin-bottom: 5px;
    font-size: 20px;
  }
}
