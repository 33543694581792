.welcome-message {
  background: white;
  padding: 0 5%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;	line-height: 16px;
  .avatar{
    height: 54px;
    width: 54px;
    border-radius: 7px;
  }
  .messages{
    width: 60%;
    word-wrap:break-word;
    margin-left: 16px;
    p{
      margin-bottom: 0.5rem;
    }
  }
}
