.chart-controller{
    height: 17vh;
    margin: 10px 0;
}
.chart-controller-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.chart-controller-arrows{
    background: white;
    border: 0;
}
.chart-controller-arrows:active,:focus{
    outline: none;
}
.chart-controller-dropdown{
    width: 100%;
    height: 27px;
    margin-top: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}
