.loader{
    height: 100%;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 30px;
}
.main_chart_block{
    width: 100%;
    overflow: scroll;
    display: flex;
    position: relative;
}
.chart_block{
    margin-left: 125px;
    width: 100%;
    overflow: scroll;
}
.chart_labels{
    position: absolute;
    width: 125px;
    top: 0;
    left:0;
    border-right: 1px solid lightgrey;
    z-index: 999;
}
.chart_labels_item{
    height: 41px;
    width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 0 0 5px;
    font-family: Roboto;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.chart_labels_item:nth-child(even) {
    height: 41px;
    background-color: #e6e6e6;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}
.chart_labels_item:last-child{
    border-bottom: 1px solid grey;
}