.wheel_block{
    position: relative;
    width: 100%;
    padding: 0 30px 0 0;
    height: 160px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    // overflow-x: hidden;
    .wheel_center{
        width: 10vw;
        height: 25px;
        position: absolute;
        top:0;
        left: 45vw;
        
        .img{
            transition: transform 0.2s;
            position: relative;
            z-index: 100;
            width: 20px;
            margin-left: calc(50% - 10px);
        }
    }
    .item{
        transition-property: transform;
        transition-duration: 0.5s;
        position: absolute;
        img{
            transition-property: opacity box-shadow;
            transition-duration: 0.5s;
            width: 80px;
            height: 80px;
            border-radius: 15px;
            box-shadow: -6px 6px 31px 0 rgba(22,25,31,0.13), 0 2px 24px -11px rgba(22,232,255,0.76);
        }
        .title{
            width:100%;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 19px;
            text-align: center;
            margin: 5px 0 0 10px;
        }
    }
}