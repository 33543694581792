@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");

.cov19_poll_question_block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
}

.cov19_poll_question_block_title {
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 19px;
  padding: 0 16px 11px;
  text-transform: uppercase;
}

.cov19_poll_question_block_content {
  padding: 16px;
  background-color: #fcefeb;
  border-bottom: 12px solid white;
  display: flex;
  justify-content: space-between;
}

.cov19_poll_options_container {
  width: 350px;
}
.cov19_poll_options_wrapper {
  display: flex;
  justify-content: space-between;
}
.cov19_poll_options_label_wrapper {
  display: flex;
  justify-content: space-between;

  color: #414248;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding-top: 8px;
}

.cov19_poll_options_bottom_btn_wrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.cov19_poll_options_bottom_btn {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.cov19_poll_options_edit_btn {
  margin-top: 16px;
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  float: right;
}

.cov19_poll_options_question {
  color: #545669;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  width: calc(100% - 370px);
}

.cov19_poll_option {
  height: 31px;
  width: 31px;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cov19_poll_option > span {
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.covid19_opaque {
  opacity: 0.3;
}
.covid19_red {
  background-color: #eb6565;
}
.covid19_blue {
  background-color: #1c93ff;
}
.covid19_green {
  background-color: #45e141;
}
.covid19_gray {
  background-color: #9e9e9e;
}
.covid19_white {
  border: 1px solid #3fa4ff;
  background-color: #ffffff;
  color: #1c93ff;
}
.covid19_white > span {
  color: #1c93ff;
}

@media all and (max-width: 767px) {
  .cov19_poll_options_container {
    width: 295px;
  }
  .cov19_poll_option {
    height: 26px;
    width: 26px;
  }
  .cov19_poll_options_question {
    width: 100%;
    margin-bottom: 10px;
  }
  .cov19_poll_options_container {
    width: 100%;
  }
  .cov19_poll_question_block_content {
    flex-direction: column;
  }
}

/* covid19 info */

.cov19_info_container {
  position: relative;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
  margin: 0 16px 16px;
  padding-top: 20px;
}

.cov19_info_text_block {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  padding: 14px;
}
.cov19_info_close {
  position: absolute;
  width: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.cov19_info_bottom {
  display: flex;
  justify-content: space-between;
  padding: 14px;
}

.cov19_info_input {
  display: flex;
  align-items: center;
}

.cov19_info_input_label {
  color: #414248;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  margin-left: 5px;
}

.cov19_info_bottom_btn {
  box-sizing: border-box;
  height: 33px;
  width: 149px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cov19_nav_top {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0;
}

.cov19_nav_top_home_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  padding: 8px 31px;
}

.cov19_nav_top_home_text {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.cov19_nav_top_title {
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.63px;
  line-height: 24px;
}

.cov19_nav_bott {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 16px;
  padding-top: 10px;
}

.cov19_title {
  padding: 0 16px 18px;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.63px;
  line-height: 24px;
}

.cov19_prev {
  width: 100px;
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cov19_next {
  width: 100px;
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

@media all and (max-width: 380px) {
  .cov19_nav_top {
    flex-wrap: wrap;
  }
  .cov19_nav_bott {
    flex-wrap: wrap;
  }

  .cov19_title {
    font-size: 16px;
  }
  .cov19_dots {
    width: 100%;
    order: 3;
    display: flex;
    justify-content: center;
  }

  .cov19_next {
    order: 2;
  }
}

/* my infection risk */

.cov19_inf_risk_container {
  width: 100%;
  padding: 20px 0;
  background-color: #f2f5fa;
  margin-bottom: 20px;
}

.cov19_inf_risk_question_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}

.cov19_inf_risk_question_text {
  color: #414248;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  padding-right: 5px;
}

.cov19_info_shade_block {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
  margin: 0 16px 16px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cov19_inf_risk_about_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 16px;
  padding-bottom: 20px;
}

.cov19_bottom_divider {
  border-bottom: 1px solid #d8d8d8;
}

.cov19_inf_risk_about_cont1_block {
  display: flex;
  align-items: center;
}

.cov19_inf_risk_about_cont2_block {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.cov19_inf_risk_input {
  width: 100px;
  height: 36px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
}

.cov19_dates_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cov19_inf_risk_input:disabled {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
}

.cov19_inf_risk_padd_right {
  padding-right: 20px;
}

.cov19_inf_risk_inp_and_sel_wrap {
  display: flex;
  flex-wrap: wrap;
}

.cov19_illnes_dates_cont {
  display: flex;
  flex-wrap: wrap;
}

.cov19_shade_block_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media all and (max-width: 970px) {
  .cov19_info_shade_block {
    flex-direction: column;
    align-items: flex-start;
  }
  .cov19_shade_block_item {
    width: 100%;
    justify-content: space-between;
  }
}

@media all and (max-width: 500px) {
  .cov19_inf_risk_question_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .cov19_inf_risk_question_text {
    margin-bottom: 5px;
  }

  .cov19_inf_risk_about_cont {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .cov19_inf_risk_about_cont1_block {
    justify-content: space-between;
  }
  .cov19_inf_risk_about_cont2_block {
    justify-content: space-between;
    padding-right: 0;
  }
  .cov19_inf_risk_padd_right {
    padding-right: 0;
  }
  .cov19_shade_block_item {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media all and (max-width: 850px) {
  .cov19_illnes_dates_cont {
    flex-direction: column;
  }
}

@media all and (max-width: 750px) {
  .cov19_illnes_dates_cont {
    justify-content: flex-end;
  }
}

@media all and (max-width: 500px) {
  .cov19_illnes_dates_cont {
    justify-content: flex-start;
  }

  .cov19_dates_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.cov19_orange_text_block {
  color: #f38938;
  font-family: Roboto;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 26px;
  padding: 20px 16px;
}

.covid19_noshrink {
  flex-shrink: 0;
}

.covid19_input_date_width {
  width: 200px;
}

/* illnes diary */

.cov19_ild_green_divider {
  border-bottom: 2px solid rgb(8, 99, 8);
  margin: 0px 16px;
}

.covid_ild_day_block {
  margin: 4px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5px 11px 3px;
}

.covid_ild_day_block > div {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

div.covid_green_text {
  color: green;
}

.covid_ild_sel_day_wrap {
  display: flex;
  flex-wrap: wrap;
}

.covid_ild_sel_day_text_big {
  padding: 10px 20px;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 500;
}
.covid_ild_sel_day_item {
  position: relative;
  border-radius: 10px;
  padding: 10px 20px;
  margin-right: 15px;
}

.covid_ild_sel_day_item_title {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  text-align: center;
}

.covid_ild_day_block.cov_day_selected {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa4ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
}
.covid_ild_day_block.cov_day_selected > div {
  color: white;
}

.covid_idl_selected_date_value_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.covid_idl_temp_daily_box {
  border: 1px solid #676767;
  border-radius: 7px;
}

.cov19_wb_option {
  width: 200px;
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.cov19_wb_option > div {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.covid_modal_header_wrapper {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.covid_modal_header_wrapper > div {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.covid19_daily_edit_icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 10px;
}

.covid_question_small_label {
  font-size: 10px;
  font-family: Roboto;
  margin-right: 5px;
  color: #414248;
}

.covid19_welcome_title {
  padding: 20px 16px 24px;
  color: #232326;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 28px;
  display: flex;
}

.covid_welcome_container {
  width: 100%;
  padding: 0px 16px 20px;
  color: #414248;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.covid_welcome_bold {
  font-weight: 900;
}

.covid_welcome_formated_html_bold {
  margin: 20px 0;
}

.covid_welcome_formated_html_bold > span > b {
  font-weight: 900;
}
.covid_welcome_italic {
  font-style: italic;
}

.covid_welcome_list_container {
  padding-left: 16px;
  margin-bottom: 20px;
}

.covid_welcome_list_item_wrap {
  display: flex;
  align-items: center;
}

.covid_welcome_small_dot_filled {
  height: 6px;
  width: 6px;
  background-color: #3fa4ff;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}

.covid19_welldone_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.covid19_welldone_title {
  color: #232326;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}

.covid19_welldone_img {
  height: 72px;
  width: 69px;
  margin: 16px 0 50px;
}

.covid19_welldone_return_btn {
  color: #3fa4ff;
  font-size: 60px;
  line-height: 1;
}

.covid19_welldone_return_text {
  font-size: 12px;
  font-family: Roboto;
  color: #414248;
}

.covid19_welldone_answers_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 12px 16px 16px;
}

.covid19_welldone_title_block {
  display: flex;
  margin-bottom: 12px;
}

.covid19_welldone_title_questions {
  width: 50%;
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}
.covid19_welldone_title_answers {
  width: 50%;
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.covid19_welldone_answer_block {
  display: flex;
  border: 1px solid #d0d7e4;
  border-radius: 4px;
  background-color: #ffffff;
}

.covid19_welldone_answer_label {
  width: 50%;
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 12px;
}

.covid19_welldone_answer_rating {
  width: 50%;
  border-left: 1px solid #d0d7e4;
  padding: 12px;
  display: flex;
  align-items: center;
}

.covid19_welldone_answer_value {
  width: 50%;
  border-left: 1px solid #d0d7e4;
  padding: 12px;
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 16px;
}

@media all and (max-width: 500px) {
  .covid19_welldone_answers_container {
    padding: 8px;
  }
  .covid19_welldone_title_questions,
  .covid19_welldone_answer_label {
    width: 65%;
  }
  .covid19_welldone_title_answers,
  .covid19_welldone_answer_rating,
  .covid19_welldone_answer_value {
    width: 35%;
  }
  .cov19_nav_top {
    padding: 8px 8px 0;
  }
  .cov19_nav_bott {
    padding: 8px;
  }
}

.loading_overlay {
  background-color: rgba(0, 0, 0, 0.37);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
