/* HubPrompter.css */

.hubprompter_container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.hubprompter_title {
  font-size: 20px;
  font-weight: 500;
}

.hubprompter_top {
  width: 80%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.hubprompter_inputfield_clear_btn {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  background-color: white;
  box-shadow: 0 0px 12px 0 rgba(25, 50, 89, 0.5);
  border-radius: 50%;
  height: 30px;
  color: #3fa5ff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.5s ease;
}

/* .hubprompter_inputfield_clear_btn {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;
} */

.hbvisble {
  opacity: 1;
  cursor: pointer;
}

.hubprompter_top_btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.hubprompter_top_btn_wrapper > button {
  height: 43px;
  width: 211px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);

  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.hubprompter_top_btn_wrapper > button:nth-of-type(2) {
  height: 43px;
  width: 211px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);

  color: #3fa4ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;

  margin-left: 20px;
}

.hubprompter_input {
  width: 100%;
  border: 1px solid #d0d7e4;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
  padding: 5px 10px;
}

.hubprompter_dashed_divider {
  width: calc(100% - 40px);
  margin: 0 20px;
  border-bottom: 1px dashed #979797;
}

.hubprompter_bottom {
  overflow: auto;
  width: 90%;
  margin: 10px auto;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
}

.hubprompter_back_arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 18px;
  /* filter: invert(100%); */
  padding: 3px;
}

/* table edit */

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border-right: 1px solid #ddd;
  padding: 4px 8px 4px 8px;
  transition: all 0.3s ease-out;
}
#customers tr:nth-child(odd) {
  background-color: #f2f5fa;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  background-color: #fff;
  color: #545669;
  vertical-align: top;
}

.info_row {
  text-transform: none;
  font-size: 12px;
  line-height: normal;
  vertical-align: top;
}

.hubprompter_td_div {
  /* border: 1px solid #ddd; */
  padding: 0 5px;
  height: 30px;
  overflow: auto;
}

/* covid19 mainpage */

.cov19_nav {
  display: flex;
  justify-content: space-between;
}

.hubprompter_modes_container {
  margin: 10px 0;
  border: 1px solid #d0d7e4;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: calc(100% - 40px);
}

.hubprompter_modes_arrow_box {
  height: 52px;
  width: 53px;
  border: 1px solid #3fa4ff;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3fa4ff;
}

.hubprompter_modes_mode_title {
  color: #232326;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 21px;
  text-align: center;
  padding-bottom: 10px;
}

.hubprompter_small_dot_wrapper {
  display: flex;
  justify-content: center;
}

.hubprompter_small_dot {
  height: 6px;
  width: 6px;
  border: 1px solid #3fa4ff;
  border-radius: 50%;
  margin: 0 5px;
}

.hubprompter_small_dot_filled {
  height: 6px;
  width: 6px;
  background-color: #3fa4ff;
  margin: 0 5px;
  border-radius: 50%;
}

.hubprompter_examp_query_btns_container {
  display: flex;
  flex-wrap: wrap;
}

.hubprompter_predef_query_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  padding: 9px 30px;
  margin-right: 13px;
}

.hubprompter_predef_query_btn_text {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.hubprompt_predef_container {
  width: 100%;
  padding: 20px 15%;
  background-color: #f2f5fa;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-height: 200px;
  overflow-y: auto;
}

.hubprompt_examp_container {
  width: 100%;
  padding: 10px 20px;
  background-color: #f2f5fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.hubprompter_result_settings_container {
  width: 100%;
  padding: 10px 20px;
  background-color: #f2f5fa;
  display: flex;
}

.hubprompter_result_settings_left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.hubprompter_result_settings_right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.hubprompter_predef_query_title {
  width: calc(50% - 20px);
  margin-bottom: 10px;
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.hubprompt_covid_banner_links_wrap {
  display: flex;
  flex-wrap: wrap;
}

.hubprompt_covid_banner_links {
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-right: 26px;
  margin-bottom: 15px;
}

.hubprompt_covid_banner_text1 {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 10px;
}

.hubprompt_covid_banner_text2 {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 15px 0 5px;
}

.hubprompt_blink_me {
  animation: blinker 2s linear 5;
}

@keyframes blinker {
  50% {
    background-color: #a2e9ff;
  }
}

.hubprompter_return_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px 0;
  flex-wrap: wrap;
}

.hubprompter_return_header_title {
  color: #232326;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.69px;
  line-height: 25px;
}

.hubprompter_return_header_inner_block {
  display: flex;
  justify-content: flex-end;
}

.hubprompter_return_header_stats {
  background-color: #f2f5fa;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  flex-wrap: wrap;
}

.hubprompter_return_header_stats > :first-child {
  margin-left: 0;
}
.hubprompter_return_header_stats > :last-child {
  margin-right: 0;
}

.hubprompter_result_settings_title {
  color: #232326;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.69px;
  line-height: 25px;
}

.hubprompter_result_settings_subtitle {
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  margin: 10px 20px 0;
}

.hubprompter_return_header_text {
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.44px;
  line-height: 16px;
  margin-right: 20px;
  padding-bottom: 5px;
}

.hubprompter_result_settings_select {
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  width: 70%;
  margin: 10px 0;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
}

.custom_search_container {
  width: 100%;
  padding: 20px;
  background-color: #f2f5fa;
}

.custom_search_droppD_title {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.custom_search_droppD_wrapper {
  margin: 10px 0;
}

.custom_search_droppD_label {
  color: #414248;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 5px;
}

.hubprompt_filtered_search_container {
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: #f2f5fa;
}

.hubprompt_filtered_search_predef {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.hubprompt_filtered_search_predef_outer_wrap {
  display: flex;
  flex-direction: column;
  height: 140px;
  overflow-y: auto;
}
.hubprompt_filtered_search_predef_inner_wrap {
  display: flex;
  flex-direction: column;
}

.hubprompt_filtered_search_predef_title {
  width: 100%;
  margin-bottom: 10px;
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  padding-right: 10px;
  display: flex;
}

.hubprompt_filtered_search_predef_qID {
  font-size: 10px;
  font-family: Roboto;
  margin-right: 5px;
  color: #414248;
  margin-right: 5px;
}

.hubprompt_filters_container {
  width: 50%;
  padding-left: 10px;
}

.hubprompt_filtered_search_step_title {
  color: #232326;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 21px;
  padding-bottom: 10px;
}

.hubprompt_filtered_search_predef_selected {
  color: #4caf50;
  padding-left: 10px;
}

.hubprompt_filtered_select {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 38px;
  padding: 2px 8px;
}

.hub_prompter_add_filter_wrapper {
  display: flex;
  margin: 10px 0 20px;
  justify-content: flex-start;
}

.hub_prompter_add_filter {
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.hub_prompter_add_filter:hover {
  color: #2681d1;
}

.hub_prompter_fil_select_wraper {
  position: relative;
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.hub_prompter_fil_select_clear {
  color: red;
  width: 24px;
  height: 24px;
}

.hub_prompter_fil_select {
  width: calc(50% - 12px);
}

.hubprompter_table_top {
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: space-between;
}

.hubprompter_table_top_left {
  display: flex;
  align-items: center;
}
.hubprompter_table_top_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hubprompter_table_limit_btn {
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.hubprompter_table_limit_btn.selected {
  color: #3fa5ff;
  border-bottom: 1px solid #3fa5ff;
}

.hubprompter_table_top_text {
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 5px;
}

.hubprompter_table_top_arrow {
  color: hsl(0, 0%, 80%);
}

.hubprompter_table_top_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 2px 5px;
  margin-left: 35px;
  cursor: pointer;
}

.hubprompter_scroll_top_wrap {
  display: flex;
  width: 90%;
  margin: 10px auto;
  justify-content: flex-end;
}

.hubprompter_scroll_top_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 2px 5px;
  cursor: pointer;
}

.hubprompter_result_settings_grouping_container {
  width: 100%;
  padding: 20px;
  background-color: #f2f5fa;
}

.hubprompter_grouping_values_container {
  display: flex;
  align-items: center;
  min-height: 32px;
}

.hubprompter_grouping_values_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.hubprompter_grouping_values_title {
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  margin-bottom: 10px;
}

.hubprompter_grouping_value {
  padding: 3px 13px;
  text-align: center;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  background-color: #ffffff;
  margin-left: 4px;
  margin-bottom: 10px;
}
.hubprompter_grouping_value.selected {
  background-color: #d6d6d6;
}

.hubprompter_grouping_groups_title {
  color: #232326;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 14px;
  padding-bottom: 2px;
  margin-bottom: 10px;
}

.hubprompter_grouping_groups_title.active {
  border-bottom: 1px solid #3fa4ff;
}

.hubprompter_grouping_bottom_btns_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
}

.hubprompter_grouping_bottom_add_btn {
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
}

.hubprompter_grouping_bottom_apply_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  padding: 5px 30px;
}

.hubprompter_statistics_charts_container {
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 0 20px;
}

.hubprompter_statistics_chart_wrap {
  position: relative;
  margin-right: 10px;
  border-right: 1px solid silver;
}

.hubprompter_statistics_charts_container
  > :last-child
  > .hubprompter_statistics_chart_wrap {
  border-right: none;
  margin-right: 0;
}

.hubprompter_statistics_chart_title {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.hubprompter_statistics_stats_container {
  padding: 10px 20px;
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.hubprompter_statistics_stats_container > div {
  margin-right: 10px;
}

.hubprompter_statistics_stats_container > :last-child {
  margin-right: 0px;
}

.hubprompter_top_error_msg {
  color: #e73e3e;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 0px 20px 10px;
}

@media all and (max-width: 970px) {
  .hubprompt_predef_container {
    padding: 20px 5%;
  }
}

@media all and (max-width: 500px) {
  .hubprompt_predef_container {
    /* flex-direction: column; */
    padding: 20px;
  }

  .hubprompter_predef_query_title {
    width: calc(100% - 40px);
  }
}

@media all and (max-width: 550px) {
  .hubprompt_filtered_search_container {
    flex-direction: column;
  }

  .hubprompt_filtered_search_predef {
    width: 100%;
    margin-bottom: 15px;
  }
  .hubprompt_filters_container {
    width: 100%;
    padding: 0;
  }
}

@media all and (max-width: 685px) {
  .hubprompter_table_top {
    flex-direction: column;
  }
  .hubprompter_table_top_left {
    border-bottom: 1px solid silver;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .hubprompter_table_top_right {
    justify-content: flex-start;
  }
}
