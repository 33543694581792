/*------------------------------------------------------------------

    [SHOP MIRKA CSS]

    Theme Name: general, grid layout customizable for any shop
    Version: 1.0
    Last change: Apr/2019

-------------------------------------------------------------------*/

/*------------------------------------------------------------------

    [Table of contents]

    0.0 CONTENT
    1.0 GENERAL
	  2.0 Receipt
	  3.0 Navigation
	  4.0 Modal div

-------------------------------------------------------------------*/

/* -----------------------------------------------------------------

                         0.0 CONTENT

------------------------------------------------------------------*/
.css2receipt_grid_container {
  --header_line1: "";
  --header_line2: "Miriam Pištejová -";
  --header_line3: "OBCHOD A SLUŽBY";
  --header_line4: "S:Domašská 34";
  --header_line5: "080 06 Ľubotice";
  --header_line6: "Pr:Levočská 2";
  --header_line7: "080 01 Prešov";
  --header_line8: "IČO: 34 654 062";
  --header_line9: "IČ DPH";
  --header_line10: "SK1020758838";
  --cashier_operator_descr: "POKLADNÍK";
  --receipt_date_desc: "Dátum";
  --receipt_time_desc: "Čas";
  --receipt_ID_desc: "Číslo:";
  --receipt_NUM_desc: "Číslo účtenky:";
  --shop_location_desc: "Prevádzka:";
  --shop_cashier_num_desc: "Pokladňa:";
  --shop_goods_desc: "Zbozi";
  --shop_goods_amount_desc: "Množstvo";
  --shop_unit_price_desc: "Jednotkova cena";
  --shop_tax_desc: "MwSt.";
  --receipt_items_count_desc: "Anz. Artikel";
  --shop_price_and_currency_desc: "Cena(EUR)";
  --item_total_price_sign: "=";
  --item_multiplication_sign: "*";
  --total_sum_desc: "CELKOM EUR";
  --tax_base_desc: "Zaklad";
  --tax_name_desc: "Dan";
  --tax_sum_desc: "Suma";
  --rounding_desc: "ZAOKRÚHLENIE";
  --cash_sum_taken_desc: "HOTOVOSŤ EUR";
  --cash_to_return_desc: "VYDAŤ EUR";
  --footer_receipt_num_desc: "POKL. DOKLAD Č.";
  --sum_to_tax: "ZDANITEĽNÉ";
  --tax_base_sum: "ZÁKLAD DANE";
  --tax_sum: "DPH";
  --tax_base_combined_desc: "ZÁKLAD DANE CELKOM";
  --tax_combined_desc: "DPH CELKOM";
  --cashier_identifier_1_desc: "DKP: 9241020758838001";
  --cashier_identifier_2_desc: "";
  --cashier_identifier_3_desc: "";
  --payment_method: "Platba:";
  --footer_line_1: "Pr:Drogeria Kozmetika";
  --footer_line_2: "";
  --footer_line_3: "";
  --footer_line_4: "";
  --footer_line_5: "";
  --footer_line_6: "";
  --footer_line_7: "";
}

#css2HEADER_LINE_1::before {
  content: var(--header_line1);
}

#css2HEADER_LINE_2::before {
  content: var(--header_line2);
}

#css2HEADER_LINE_3::before {
  content: var(--header_line3);
}

#css2HEADER_LINE_4::before {
  content: var(--header_line4);
}

#css2HEADER_LINE_5::before {
  content: var(--header_line5);
}

#css2HEADER_LINE_6::before {
  content: var(--header_line6);
}

#css2HEADER_LINE_7::before {
  content: var(--header_line7);
}

#css2HEADER_LINE_8::before {
  content: var(--header_line8);
}

#css2HEADER_LINE_9::before {
  content: var(--header_line9);
}

#css2HEADER_LINE_10::before {
  content: var(--header_line10);
}

#css2HEADER_CASHIER_OPERATOR::before {
  content: var(--cashier_operator_descr);
}

/* #css2HEADER_DATE::before {
  content: var(--receipt_date_desc);
} */

/* #css2HEADER_TIME::before {
  content: var(--receipt_time_desc);
} */

/* #css2HEADER_RECEIPT_ID::before {
  content: var(--receipt_ID_desc);
} */

/* #css2HEADER_RECEIPT_NUM_1::before {
  content: var(--receipt_NUM_desc);
} */

/* #css2HEADER_LOCATION_1::before {
  content: var(--shop_location_desc);
} */

/* #css2HEADER_CASHIER_NUM_1::before {
  content: var(--shop_cashier_num_desc);
} */

/* #css2SHOP_GOODS_NAME::before {
  content: var(--shop_goods_desc);
} */

/* #css2SHOP_GOODS_AMOUNT::before {
  content: var(--shop_goods_amount_desc);
} */

/* #css2SHOP_UNIT_PRICE::before {
  content: var(--shop_unit_price_desc);
} */

/* #css2SHOP_PRICE_AND_CURRENCY::before {
  content: var(--shop_price_and_currency_desc);
} */

.css2total_item_price_incl_tax::before {
  content: var(--item_total_price_sign);
}

.css2unit_price_incl_tax::before {
  content: var(--item_multiplication_sign);
}

#css2FOOTER_SUM_DESC::before {
  content: var(--total_sum_desc);
}

#css2CASH_SUM_TAKEN_DESC::before {
  content: var(--cash_sum_taken_desc);
}

#css2CASH_TO_RETURN_DESC::before {
  content: var(--cash_to_return_desc);
}

#css2FOOTER_RECEIPT_NUM_DESC::before {
  content: var(--footer_receipt_num_desc);
}

#css2SUM_WITHOUT_VAT_BIG_TOTAL_DESC::before {
  content: var(--tax_base_combined_desc);
}

#css2SUM_VAT_BIG_TOTAL_DESC::before {
  content: var(--tax_combined_desc);
}

#css2TAX_BASE_DESC::before {
  content: var(--tax_base_desc);
}

#css2TAX_NAME_DESC::before {
  content: var(--tax_name_desc);
}

#css2TAX_SUM_DESC::before {
  content: var(--tax_sum_desc);
}

#css2ROUNDING_DESC::before {
  content: var(--rounding_desc);
}

#css2ITEMS_COUNT_DESC::before {
  content: var(--receipt_items_count_desc);
}

.css2FOR_TAX_DESC::after {
  content: var(--sum_to_tax);
}

.css2TAX_BASE_DESC::before {
  content: var(--tax_base_sum);
}

.css2TAX_SUM_DESC::before {
  content: var(--tax_sum);
}

#css2CASHIER_IDENTIFIER_1::before {
  content: var(--cashier_identifier_1_desc);
}

#css2CASHIER_IDENTIFIER_2::before {
  content: var(--cashier_identifier_2_desc);
}

#css2CASHIER_IDENTIFIER_3::before {
  content: var(--cashier_identifier_3_desc);
}

/* #css2PAYMENT_METHOD::before {
  content: var(--payment_method);
} */

#css2FOOTER_LINE_1::before {
  content: var(--footer_line_1);
}

#css2FOOTER_LINE_2::before {
  content: var(--footer_line_2);
}

#css2FOOTER_LINE_3::before {
  content: var(--footer_line_3);
}

#css2FOOTER_LINE_4::before {
  content: var(--footer_line_4);
}

#css2FOOTER_LINE_5::before {
  content: var(--footer_line_5);
}

#css2FOOTER_LINE_6::before {
  content: var(--footer_line_6);
}

#css2FOOTER_LINE_7::before {
  content: var(--footer_line_7);
}

/* -----------------------------------------------------------------

                         1.0 GENERAL

------------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
}
@import url("https://fonts.googleapis.com/css?family=Oxygen+Mono&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bai+Jamjuree:600");

/* -----------------------------------------------------------------

                         2.0 Receipt

------------------------------------------------------------------*/

.css2receipt_grid_container {
  line-height: 1;
  display: grid;
  grid-template-areas:
    ".  L0"
    "L1 L1"
    "L2 L2"
    "L3 L3"
    "L4 L4"
    "L5 L5"
    "L6 L6"
    "L7 L7"
    "L8 L8"
    "L9a L9b"
    "L10a L10b"
    "L11 L11"
    "itemD itemD"
    "L12 L12"
    "wrapper wrapper"
    "spacer1 spacer1"
    "L13 L13"
    "L14 L14"
    /* "L14 L14"
    "L15 L15"
    "L16 L16"
    "L17 L17"
    "L18 L18"
    "L19 L19" */
    "L20 L20"
    "L21 L21"
    "L22 L22"
    "spacer2 spacer2"
    "L23 L23"
    "L24 L24"
    "L25 L25"
    "L26 L26"
    "L27 L27"
    "L28 L28";
  background-color: white;
  width: 320px;
  /* font-family: sans-serif; */
  font-family: "Courier New", Arial;
  font-size: 19px;
  padding: 15px;
  margin: 5px auto;
  border: 10px solid lightgrey;
  /* box-shadow: 5px 5px 5px grey; */
  letter-spacing: 0;
  border-radius: 10px;
}

/* ============================ */
/* MAIN GRID SPACERS */
/* ============================ */
.css2spacer0 {
  height: 30px;
  grid-area: L0;
  text-align: center;
  border: none;
  background: white;
}
.css2spacer0:active, .css2spacer0:focus{
  outline: none;
}

.css2spacer1 {
  grid-area: spacer1;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
}

.css2spacer2 {
  grid-area: spacer2;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
}

.css2spacer3 {
  grid-area: spacer3;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
  display: none;
}

.css2spacer4 {
  grid-area: spacer4;
  height: 10px;
  background: url("/assets/images/double_arrow_spacer.png") repeat-x 0 0;
  border: 0;
  margin: 5px 0;
  display: none;
}

#css2HEADER_LINE_1 {
  grid-area: L1;
  background-image: url("/assets/images/drogeriamirka.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 153px;
}

#css2HEADER_LINE_2 {
  margin-top: 10px;
  grid-area: L2;
  justify-self: center;
}

#css2HEADER_LINE_3 {
  grid-area: L3;
  justify-self: center;
}

#css2HEADER_LINE_4 {
  grid-area: L4;
  justify-self: center;
}

#css2HEADER_LINE_5 {
  grid-area: L5;
  justify-self: center;
}

#css2HEADER_LINE_6 {
  grid-area: L6;
  justify-self: center;
}

#css2HEADER_LINE_7 {
  grid-area: L7;
  justify-self: center;
}

#css2HEADER_LINE_8 {
  grid-area: L8;
  justify-self: center;
}

#css2HEADER_LINE_9 {
  grid-area: L9a;
}

#css2HEADER_LINE_10 {
  grid-area: L9b;
  display: flex;
  justify-self: end;
}

#css2HEADER_TIME_HM_DELIM_COL {
  display: none;
}

#css2HEADER_TIME_HMS_DELIM_COL {
  grid-area: L10a;
}

#css2HEADER_DATE_DELIM_HYPHEN {
  grid-area: L10b;
  display: flex;
  justify-self: end;
}

#css2HEADER_DATE_DELIM_DOT {
  display: none;
}

#css2HEADER_DAY_NAME {
  grid-area: L10b;
  display: flex;
  justify-self: end;
  margin-right: 120px;
}

#css2HEADER_CASHIER_OPERATOR {
  grid-area: L11;
  display: flex;
  justify-self: end;
}

#css2HEADER_RECEIPT_ID {
  display: none;
}

#css2HEADER_RECEIPT_NUM_1 {
  display: none;
}

#css2HEADER_RECEIPT_NUM_2 {
  display: none;
}

#css2HEADER_LOCATION_1 {
  display: none;
}

#css2HEADER_LOCATION_2 {
  display: none;
}

#css2HEADER_CASHIER_NUM_1 {
  display: none;
}

#css2HEADER_CASHIER_NUM_2 {
  display: none;
}

/* ============================ */
/* CONTENT GRID CONTAINTER */
/* ============================ */

.css2receipt_content {
  grid-area: L12;
}

#css2SHOP_GOODS_NAME {
  display: none;
}

#css2SHOP_GOODS_AMOUNT {
  display: none;
}

#css2SHOP_UNIT_PRICE {
  display: none;
}

#css2SHOP_PRICE_AND_CURRENCY {
  display: none;
}

.css2item_wrapper {
  grid-area: wrapper;
  margin-top: 20px;
  margin-bottom: 20px;
}

.css2item_inner_wrapper_grid {
  display: grid;
  grid-template:
    "con_des con_des con_des con_des"
    "con_col0 con_col1 con_col2 con_col3";
  grid-template-columns: min-content min-content auto auto;
}

/* .css2single_item {
  display: none;
} */

.css2item_description_class {
  grid-area: con_des;
}

.css2item_id_class {
  display: none;
}

.css2item_ean_class {
  display: none;
}

.css2item_free_parameter1_class {
  display: none;
}

.css2item_amount {
  grid-area: con_col0;
}

.css2item_measure_unit {
  grid-area: con_col1;
}

.css2unit_price_incl_tax {
  grid-area: con_col2;
}

.css2total_item_price_incl_tax {
  grid-area: con_col3;
  display: flex;
  justify-self: end;
  margin-right: 20px;
}

.css2tax_group_descr {
  grid-area: con_col3;
  display: flex;
  justify-self: end;
}

.css2tax_group_value {
  display: none;
}

/* ============================ */

#css2ITEMS_COUNT_DESC {
  display: none;
}

#css2ITEMS_COUNT_NO_DECIMALS {
  display: none;
}

#css2ITEMS_COUNT_ONE_DECIMAL {
  display: none;
}

#css2ITEMS_COUNT_TWO_DECIMAL {
  display: none;
}

#css2ROUNDING_DESC {
  grid-area: L13;
}

#css2ROUNDING {
  grid-area: L13;
  display: flex;
  justify-self: end;
}

#css2TAX_BASE_DESC {
  display: none;
}

#css2TAX_NAME_DESC {
  display: none;
}

#css2TAX_SUM_DESC {
  display: none;
}

.css2vat_grid_wrapper {
  grid-area: L14;
}

.css2vat_grid_container {
  display: grid;
  grid-template-areas:
    "L1 L1"
    "L2 L2"
    "L3 L3";
}

.css2VAT_RATE {
  grid-area: L3;
  margin-left: 50px;
}

.css2VAT_CATEGORY_ROW1 {
  grid-area: L1;
  margin-left: 120px;
}
.css2VAT_CATEGORY_ROW2 {
  grid-area: L2;
  margin-left: 135px;
}
.css2VAT_CATEGORY_ROW3 {
  grid-area: L3;
  margin-left: 125px;
}

.css2FOR_TAX_DESC {
  grid-area: L1;
}

.css2FOR_TAX_SUM {
  grid-area: L1;
  display: flex;
  justify-self: end;
}

.css2TAX_BASE_DESC {
  grid-area: L2;
}

.css2TAX_BASE_SUM {
  grid-area: L2;
  display: flex;
  justify-self: end;
}

.css2TAX_SUM_DESC {
  grid-area: L3;
}

.css2TAX_SUM {
  grid-area: L3;
  display: flex;
  justify-self: end;
}

#css2BIG_TOTAL_PRICE_DESC {
  display: none;
}

#css2BIG_TOTAL_PRICE {
  display: none;
}

#css2SUM_WITHOUT_VAT_BIG_TOTAL_DESC {
  grid-area: L20;
}

#css2SUM_WITHOUT_VAT_BIG_TOTAL {
  grid-area: L20;
  display: flex;
  justify-self: end;
}

#css2SUM_VAT_BIG_TOTAL_DESC {
  grid-area: L21;
}

#css2SUM_VAT_BIG_TOTAL {
  grid-area: L21;
  display: flex;
  justify-self: end;
}

#css2FOOTER_SUM_DESC {
  grid-area: L22;
}

#css2FOOTER_SUM_DESC::first-letter {
  font-weight: bold;
}

#css2CART_TOTAL_PRICE {
  grid-area: L22;
  display: flex;
  justify-self: end;
}

#css2CASH_SUM_TAKEN_DESC {
  grid-area: L23;
}

#css2CASH_SUM_TAKEN_DESC::first-letter {
  font-weight: bold;
}

#css2CASH_SUM_TAKEN {
  grid-area: L23;
  display: flex;
  justify-self: end;
}

#css2CASH_TO_RETURN_DESC {
  grid-area: L24;
}

#css2CASH_TO_RETURN_DESC::first-letter {
  font-weight: bold;
}

#css2CASH_TO_RETURN {
  grid-area: L24;
  display: flex;
  justify-self: end;
}

.css2CASH_TO_RETURN_ZERO {
  display: block;
}

#css2FOOTER_RECEIPT_NUM_DESC {
  grid-area: L25;
}

#css2FOOTER_RECEIPT_NUM {
  grid-area: L25;
  display: flex;
  justify-self: end;
}

#css2CASHIER_IDENTIFIER_1 {
  grid-area: L26;
  display: flex;
  justify-self: center;
}

#css2CASHIER_IDENTIFIER_2 {
  display: none;
}

#css2CASHIER_IDENTIFIER_3 {
  display: none;
}

#css2PAYMENT_METHOD {
  display: none;
}

#css2PAYMENT_REGISTRATION_TYPE {
  display: none;
}

#css2FOOTER_LINE_1 {
  grid-area: L27;
  display: flex;
  justify-self: center;
  margin-top: 20px;
}

#css2FOOTER_LINE_2 {
  display: none;
}

#css2FOOTER_LINE_3 {
  display: none;
}

#css2FOOTER_LINE_4 {
  display: none;
}

#css2FOOTER_LINE_5 {
  display: none;
}

#css2FOOTER_LINE_6 {
  display: none;
}

#css2FOOTER_LINE_7 {
  display: none;
}

/* -----------------------------------------------------------------

                         3.0 Navigation

------------------------------------------------------------------*/

/* -----------------------------------------------------------------

                         MEDIA QUERIES

------------------------------------------------------------------*/

@media screen and (max-width: 375px) {
  .css2receipt_grid_container {
    width: 290px;
    font-size: 16px;
  }

  #css2HEADER_LINE_1 {
    height: 135px;
  }
}

@media screen and (max-width: 330px) {
  .css2receipt_grid_container {
    width: 240px;
    font-size: 12px;
  }

  #css2HEADER_LINE_1 {
    height: 100px;
  }
}
