/* loginCovid.css */

.loginCovid_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginCovid_wrapper {
  width: 380px;
  display: flex;
}

.loginCovid_expand_wrapper {
  width: 380px;
  max-height: 1000px;
  margin-top: 10px;
  transition: max-height 0.5s ease-in;
  overflow: hidden;
}

.loginCovid_colapsed {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

#menu:hover #list {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.loginCovid_img {
  height: 88px;
  width: 88px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}

.loginCovid_text_block,
.loginCovid_text_block_top {
  color: #545669;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: justify;
  text-justify: inter-word;
}

.loginCovid_text_block > ul {
  list-style-position: inside;
}

@media all and (max-width: 400px) {
  .loginCovid_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .loginCovid_img {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .loginCovid_text_block_top {
    width: 90%;
  }

  .loginCovid_expand_wrapper {
    width: 90%;
  }
}
