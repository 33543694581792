.maps-map-container{
    position: relative;
    height: 60vh;
}
.maps-controls{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.maps-controls-item{
    width: 30%;
    flex: auto;
}