/* BookcolLibSortingComp.css */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolLibSortingComp {
  display: flex;
  position: relative;
  justify-content: flex-end;
  font-size: 12px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
}

.blsc_sort_icon {
  cursor: pointer;
  font-size: 17px;
  /* align-self: center; */
  margin-right: 10px;
  font-weight: 400;
}

.blsc_sort_options_container {
  border: 1px solid grey;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  margin-top: 30px;
  right: 15px;
  padding: 5px;

  display: grid;
  grid-template-areas: "a b";

  animation: blsc_options_entrance 1s 1;
  animation-timing-function: ease;
}

@keyframes blsc_options_entrance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.blsc_asc_desc_arrow_btn {
  padding: 1px 6px 2px 6px;
  border: 1px solid grey;
  border-radius: 50%;
  margin: 0 5px;
}

.blsc_curr_filter_and_reset {
  position: absolute;
  z-index: 1;
  margin-top: 25px;
  right: 10px;
  padding: 5px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
}

.blsc_curr_filtandres_X_btn {
  color: red;
  padding-right: 10px;
  cursor: pointer;
}

.blsc_curr_filtandres_dir {
  padding-left: 10px;
}

.blsc_grid_cell {
  margin: 5px 0;
}
