/* logout.css */

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

.grid-container_logout {
  font-family: "Roboto", sans-serif;
  margin: 20px auto;
  display: grid;
  grid-template-areas:
    "item1 item1"
    "item2 item2"
    "item6 item6"
    "item3 item4"
    "item5 item5"
    "item7 item7"
    "item8 item8";
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(55, 123, 248);
  padding: 20px;
  width: 300px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

#h2_lo {
  margin: 0;
  grid-area: item1;
  text-align: center;
  color: white;
}

#return_to_login {
  display: flex;
  grid-area: item2;
  justify-self: stretch;
  justify-content: center;
}

.grid-container_logout > span {
  margin: auto 0;
  padding: 0;
  font-weight: 700;
}

#hub_lo {
  text-align: center;
  grid-area: item3;
  color: white;
}

#hub_lo > p {
  margin: 0;
}

#hub_lo > p:nth-child(2) {
  font-size: 30px;
  font-weight: 700;
}

#pin_lo {
  text-align: center;
  grid-area: item4;
  color: white;
}

#pin_lo > p {
  margin: 0;
}

#pin_lo > p:nth-child(2) {
  font-size: 30px;
  font-weight: 700;
}

/* https://www.bestcssbuttongenerator.com/ */
#return_to_hub_button {
  grid-area: item5;
  display: flex;
  justify-self: stretch;
  justify-content: center;
}

.button_lo {
  width: 100%;
  height: 60px;
  -moz-box-shadow: 0px 5px 14px -7px #000000;
  -webkit-box-shadow: 0px 5px 14px -7px #000000;
  box-shadow: 0px 5px 14px -7px #000000;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #f9f9f9),
    color-stop(1, #e9e9e9)
  );
  background: -moz-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: -webkit-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: -o-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: -ms-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9', GradientType=0);
  background-color: #f9f9f9;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  color: #666666;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}

.button_lo:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #e9e9e9),
    color-stop(1, #f9f9f9)
  );
  background: -moz-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: -webkit-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: -o-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: -ms-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9e9e9', endColorstr='#f9f9f9', GradientType=0);
  background-color: #e9e9e9;
}

.button_lo:active {
  position: relative;
  top: 1px;
}

.button_lo > span {
  align-self: center;
}

#logout_change_pin {
  grid-area: item7;
  height: 30px;
  display: flex;
  justify-self: stretch;
  justify-content: center;
}
#change_pin_input {
  grid-area: item7;
}
.change_pin_input {
  width: 100%;
  margin: 5px 0;
  color: white;
  font-weight: bold;
}
.change_pin_submit {
  color: #666;
  height: 30px;
  width: 100%;
}

.change_pin_error {
  grid-area: item8;
  color: white;
  font-weight: bold;
  background-color: red;
}

.warning_text {
  grid-area: item6;
  width: 100%;
  padding: 5px;
  color: white;
  font-weight: bold;
  margin: 0;
}

.button {
  width: 100%;
  height: 60px;
  -moz-box-shadow: 0px 5px 14px -7px #000000;
  -webkit-box-shadow: 0px 5px 14px -7px #000000;
  box-shadow: 0px 5px 14px -7px #000000;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #f9f9f9),
    color-stop(1, #e9e9e9)
  );
  background: -moz-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: -webkit-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: -o-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: -ms-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9', GradientType=0);
  background-color: #f9f9f9;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  color: #666666;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}

.button:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #e9e9e9),
    color-stop(1, #f9f9f9)
  );
  background: -moz-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: -webkit-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: -o-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: -ms-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9e9e9', endColorstr='#f9f9f9', GradientType=0);
  background-color: #e9e9e9;
}

.button:active {
  position: relative;
  top: 1px;
}
