.upg_modal_container {
  width: 100%;
  position: relative;
}

.upg_modal_close_btn {
  position: absolute;
  right: -15px;
  top: -13px;
}

.upg_modal_title_wrap {
  padding-top: 8px;
  text-align: center;
  width: 100%;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
  padding-bottom: 20px;
}

.upg_modal_result_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upg_modal_result_item_title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 20px;
  color: #6e6e72;
  text-transform: capitalize;
}

.upg_modal_result_icon_success {
  color: #7cdb80;
}

.upg_modal_result_icon_error {
  color: #e6817a;
}
.upg_modal_result_subtitle {
  padding: 30px 0 20px;
  text-align: center;
  width: 100%;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
}
