/* BookcolSingleBook.css */

/* bsb_ prefix for BookcolSingleBook */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolSingleBook {
  position: absolute;
  font-family: "Ubuntu", sans-serif;
}

.bsb_round_corner_background {
  background-color: white;
  border-radius: 0px 130px 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 127px);
  z-index: 1;
}

#bsb_top_bookshelf_bg {
  width: 100vw;
}

.bsb_book_data_wrapper {
  display: flex;
  flex-direction: column;
}

.slider-frame {
  height: 250px !important;
  padding-top: 75px !important;
}

.carousel-image {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
  -webkit-box-reflect: below 1px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6), rgba(
          0,
          0,
          0,
          0.1
        )
        20%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0));
}

.carousel-style {
  transform: scale(1.4);
}
.bsb_book_img_wrapper {
  align-self: center;
  display: flex;
  width: 100vw;
  justify-content: center;
  margin-top: -40px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.bsb_curr_book_img_wrapper {
  background-color: white;
  border: 1px solid white;
  border-radius: 15px;
  position: relative;
  width: 40%;
}

.bsb_curr_book_img_frame {
  width: 100%;
  min-height: 58vw;
}

div.bsb_curr_book_img_frame:first-of-type {
  width: 100%;
  min-height: 58vw;
  display: flex;
}

.bsb_prev_book_container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 30%;
}

.bsb_next_book_container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 30%;
}

.bsb_curr_book_img {
  border: 10px solid transparent;
  /* height: 240px;
  max-width: 260px; */
  width: 100%;
}

img.bsb_curr_book_img:first-of-type {
  border: 10px solid transparent;
  align-self: flex-end;
  width: 100%;
}

figure {
  box-shadow: none !important;
}
.carousel-image {
  margin: auto;
  height: 100px;
  max-width: 100px;
}

.bsb_prev_book_arrow_img {
  height: 40px;
  width: 30px;
  margin: 0 0 0 auto;
  margin-top: 24px;
}

.bsb_next_book_arrow_img {
  height: 40px;
  width: 30px;
  transform: scaleX(-1);
  margin-top: 24px;
}

.bsb_prev_book_img {
  max-height: 100px;
  margin-right: 20px;
  margin-left: auto;
  width: 55%;
  float: right;
  align-self: flex-end;
}

.bsb_side_book_img_frame {
  width: 100%;
  min-height: 28vw;
  display: flex;
}

.bsb_next_book_img {
  max-height: 100px;
  margin-left: 20px;
  margin-right: 3px;
  width: 55%;
  align-self: flex-end;
}

.bsb_book_data_wrapper > div {
  align-self: center;
}

.bsb_top_back_arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 18px;
  /* filter: invert(100%); */
  padding: 3px;
  z-index: 100;
}

.bsb_books_count {
  top: 15px;
  position: absolute;
  left: 15px;
}

.bsb_book_title_and_edit {
  width: 250px;
  position: relative;
  color: black;
  -webkit-text-fill-color: black;
  opacity: 1;
}

.bsb_book_author {
  max-width: 90vw;
}

.bsb_book_data_content_container {
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*margin: -30vh 30px 0px 30px;*/
  margin: 0 30px 0px 30px;
  align-items: center;
}
.bsb_book_title {
  font-weight: 500;
  font-size: 18px;
  overflow-y: auto;
  width: 250px;
  text-align: center;
  max-height: 54px;
  background-color: transparent;
  border: none;
  color: black;
  -webkit-text-fill-color: black;
  opacity: 1;
}

#bsb_edit_pencil {
  position: absolute;
  bottom: 10px;
  right: -20px;
  width: 20px;
  height: 20px;
}

.bsb_border_box {
  padding: 6px 10px;
  min-width: 330px;
  min-height: 150px;
  border: 1px solid black;
}
.bsb_box {
  padding: 6px 10px;
  min-width: 330px;
  min-height: 150px;
}

.bsb_border_box:nth-child(4) {
  margin-top: 20px;
}

.bsb_loading_wrapper {
  display: flex;
  justify-content: center;
}

.bsb_top_loading {
  align-self: center;
  clip-path: circle(20% at 50% 50%);
}
.fixPositionLoading {
  z-index: 300;
}
.bsb_bottom_box_input {
  width: 100%;
  background-color: transparent;
  border: none;
}

.bsb_bottom_box_input::placeholder {
  color: white;
}
.bsb_mark_inputs_as_editable {
  background-color: #04040440 !important;
}
.bsb_mark_inputs_as_editable::placeholder {
  color: black !important;
  -webkit-text-fill-color: black;
  opacity: 1;
}

.bsb_bottom_box_textarea {
  line-height: 1;
}

.bsb_prog_bar_base {
  margin: 0 20px;
  height: 25px;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  );
}

.bsb_prog_bar_runner {
  border-radius: 10px;
  height: 25px;
  background: linear-gradient(
    to bottom,
    rgba(46, 197, 234, 1) 0%,
    rgba(47, 168, 249, 1) 50%,
    rgba(37, 189, 232, 1) 52%,
    rgba(86, 247, 241, 1) 100%
  );
}

.bsb_prog_bar_text {
  height: 25px;
  white-space: nowrap;
  color: white;
  padding-left: 10px;
}

.bsb_box_inner_content {
  margin-top: -5px;
}

.bsb_box_inner_content_wrapper {
  margin-top: 10px;
}

.bsb_reflection {
  position: relative;
}
.bsb_reflection img {
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: flipv;
  opacity: 0.2;
  filter: alpha(opacity= "20");
  margin-top: 0px;
}
.bsb_overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 120px;
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(255, 255, 255) 60%,
    rgba(255, 255, 255, 0) 75%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 25%,
    rgb(255, 255, 255) 40%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.6, rgb(255, 255, 255)),
    color-stop(0.75, rgba(255, 255, 255, 0))
  );
  filter: progid:DXImageTransform.Microsoft.Gradient( gradientType=0, startColor=0, EndColorStr=#ffffff);
}
.bsb_curr_reflection {
  position: relative;
}
.bsb_curr_reflection img {
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: flipv;
  opacity: 0.1;
  filter: alpha(opacity= "20");
  margin-top: -20px;
}
.bsb_curr_overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 58vw;
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(255, 255, 255) 80%,
    rgba(255, 255, 255, 0) 90%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 10%,
    rgb(255, 255, 255) 20%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.8, rgb(255, 255, 255)),
    color-stop(0.9, rgba(255, 255, 255, 0))
  );
  filter: progid:DXImageTransform.Microsoft.Gradient( gradientType=0, startColor=0, EndColorStr=#ffffff);
}

.bsb_slider_container {
  margin: 10px 0;
}

.bsb_slider_daystotal {
  text-align: center;
}

.bsb_slider_startToEnd {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.bsb_box_start_end_btn_wrapper {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.bsb_box_start_btn {
  -moz-box-shadow: 0px 1px 0px 0px #3dc21b;
  -webkit-box-shadow: 0px 1px 0px 0px #3dc21b;
  box-shadow: 0px 1px 0px 0px #3dc21b;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #44c767),
    color-stop(1, #5cbf2a)
  );
  background: -moz-linear-gradient(top, #44c767 5%, #5cbf2a 100%);
  background: -webkit-linear-gradient(top, #44c767 5%, #5cbf2a 100%);
  background: -o-linear-gradient(top, #44c767 5%, #5cbf2a 100%);
  background: -ms-linear-gradient(top, #44c767 5%, #5cbf2a 100%);
  background: linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#44c767', endColorstr='#5cbf2a',GradientType=0);
  background-color: #44c767;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 15px;
  text-decoration: none;
}
.bsb_box_start_btn:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #5cbf2a),
    color-stop(1, #44c767)
  );
  background: -moz-linear-gradient(top, #5cbf2a 5%, #44c767 100%);
  background: -webkit-linear-gradient(top, #5cbf2a 5%, #44c767 100%);
  background: -o-linear-gradient(top, #5cbf2a 5%, #44c767 100%);
  background: -ms-linear-gradient(top, #5cbf2a 5%, #44c767 100%);
  background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5cbf2a', endColorstr='#44c767',GradientType=0);
  background-color: #5cbf2a;
}
.bsb_box_start_btn:active {
  position: relative;
  top: 1px;
}

.bsb_box_end_btn {
  -moz-box-shadow: 0px 1px 0px 0px #8a2a21;
  -webkit-box-shadow: 0px 1px 0px 0px #8a2a21;
  box-shadow: 0px 1px 0px 0px #8a2a21;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #c62d1f),
    color-stop(1, #f24437)
  );
  background: -moz-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: -webkit-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: -o-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: -ms-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background: linear-gradient(to bottom, #c62d1f 5%, #f24437 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c62d1f', endColorstr='#f24437',GradientType=0);
  background-color: #c62d1f;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #d02718;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 15px;
  text-decoration: none;
}
.bsb_box_end_btn:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #f24437),
    color-stop(1, #c62d1f)
  );
  background: -moz-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: -webkit-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: -o-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: -ms-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background: linear-gradient(to bottom, #f24437 5%, #c62d1f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f24437', endColorstr='#c62d1f',GradientType=0);
  background-color: #f24437;
}
.bsb_box_end_btn:active {
  position: relative;
  top: 1px;
}
