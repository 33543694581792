/* BookcolSingleBook.css */

/* bib_ prefix for BookcolSingleBook */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolSingleBook {
  position: absolute;
  font-family: "Ubuntu", sans-serif;
}

.bib_round_corner_background {
  background-color: white;
  border-radius: 0px 130px 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 127px);
  z-index: 1;
}

#bib_top_bookshelf_bg {
  width: 100vw;
}

.bib_book_data_wrapper {
  display: flex;
  flex-direction: column;
}

.bib_book_img_wrapper {
  align-self: center;
  background-color: white;
  margin-top: -50px;
  position: relative;
}

.bib_book_img_wrapper > img {
  border: 10px solid white;
  height: 200px;
}

.bib_book_data_wrapper > div {
  align-self: center;
  margin: 5px 30px;
}

.bib_top_back_arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 18px;
  /* filter: invert(100%); */
  padding: 3px;
}

.bib_books_count {
  top: 50px;
  position: absolute;
  left: -50px;
  padding: 3px;
}

.bib_book_title_and_edit {
  width: 250px;
  position: relative;
}
.bib_book_title {
  font-weight: bold;
  font-size: 18px;
  overflow-y: auto;
  width: 250px;
  text-align: center;
  max-height: 54px;
}

#bib_edit_pencil {
  position: absolute;
  bottom: 10px;
  right: -20px;
  width: 20px;
  height: 20px;
}

.bib_border_box {
  padding: 6px 10px;
  min-width: 330px;
  min-height: 150px;
  border: 1px solid black;
}
.bib_box {
  padding: 6px 10px;
  min-width: 330px;
  min-height: 150px;
}

.bib_border_box:nth-child(4) {
  margin-top: 20px;
}

.bib_loading_wrapper {
  display: flex;
  justify-content: center;
}

.bib_top_loading {
  align-self: center;
}

.bib_bottom_box_input {
  width: 100%;
}
