.upgrade_page_root {
  position: relative;
}

.upgrade_page_bg {
  position: absolute;
  filter: hue-rotate(210deg);
  opacity: 0.15;
  width: 100vw;
}

.upgrade_page_container {
  position: relative;
  width: 100%;
}

.upgrade_page_back_button {
  position: absolute;
  height: 19px;
  width: fit-content;
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 15px;
  z-index: 20;
  top: 10px;
  left: 10px;
}

.upgrade_page_title_text {
  padding-top: 8px;
  text-align: center;
  width: 100%;
  color: #232326;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 24px;
}

.upgrade_page_history_container {
  margin: 20px 30px;
}

.upgrade_page_history_text {
  color: #96969a;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.upgrade_page_history_text > span {
  font-weight: bold;
}

.upgrade_page_sql_hist_text {
  color: #96969a;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
  padding: 10px 0 0 20px;
}

.upgrade_page_sql_hist_text > span {
  font-weight: bold;
  color: #3fa4ff;
  white-space: nowrap;
  cursor: pointer;
}

.upgrade_page_block {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 6px 18px 0px rgba(0, 0, 0, 0.24);
  padding: 15px;
  margin: 20px;
}

.upgrade_page_block_top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.upgrade_page_block_top_upper {
  display: flex;
  align-items: center;
}

/* .upgrade_page_block_top_right {
} */

.upgrade_page_info_checkbox_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.upgrade_page_download_btn_wrap {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-shrink: 0;
  cursor: pointer;
}

.upgrade_page_subtitle {
  color: #232326;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.63px;
  line-height: 20px;
  padding: 0 20px;
  text-transform: capitalize;
}

.upgrade_page_info_btn_wrap {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: white;
  background-color: #3fa4ff;
  position: relative;
}

.upgrade_page_info_btn_close {
  position: absolute;
  transition: all 0.5s ease !important;
  cursor: pointer;
}

.upgrade_page_info_btn_show {
  position: absolute;
  transition: all 0.5s ease !important;
  cursor: pointer;
}

.bpibhide {
  opacity: 0;
}

.upgrade_page_info_checkbox_title {
  color: #232326;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.63px;
  line-height: 18px;
}

.upgrade_page_info_checkbox {
  margin-right: 10px;
}

.upgrade_page_block_bottom {
  color: #96969a;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease;
}

.upgrade_page_mid_title_container {
  display: flex;
  align-items: center;
}

.upgrade_page_show_selective_arrow {
  color: #96969a;
  transition: all 0.5s ease !important;
}

.upgrade_page_show_selective_arrow.upgprotate {
  transform: rotate(-180deg);
}

.upgrade_page_show_selective_blocks_container {
  transition: all 400ms ease;
  overflow: hidden;
  max-height: 0;
}

.upgrade_page_seelctive_whole {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.upgrade_page_seelctive_part {
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.upgrade_page_selective_text {
  color: #96969a;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
  padding: 5px 0;
}

.upgrade_page_selective_text > span {
  font-weight: bold;
  color: #3fa4ff;
  white-space: nowrap;
  cursor: pointer;
}

.upgrade_page_block_bottom_divider {
  width: 100%;
  border-top: 1px solid #6e6e72;
  margin-bottom: 10px;
}

.upgrade_page_selected_apply_container {
  display: flex;
  padding: 0 20px 20px;
}

.upgrade_page_selected_apply_btn_wrap {
  display: flex;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 6px 18px 0px rgba(0, 0, 0, 0.24);
  padding: 10px;
  cursor: pointer;
}

.upgrade_page_selected_apply_btn {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  margin-left: 20px;
}

.upgrade_page_selected_apply_btn > span {
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.upgrade_page_block_allsql_container {
  overflow-x: auto;
}

.upgrade_page_block_sql_all_piece {
  padding-bottom: 20px;
  white-space: nowrap;
  color: #434346;
  font-weight: 600;
}

.upg_selective_item_expand_container {
  color: #434346;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  overflow-x: auto;
  font-weight: 600;
  max-height: 0;
  transition: all 0.5s ease;
  margin-left: 63px;
  white-space: nowrap;
}

@media all and (max-width: 550px) {
  .upgrade_page_title_text {
    padding-top: 40px;
  }
}
