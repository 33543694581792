.trash_block {
  position: absolute;
  height: 50px;
  width: 50px;
  left: -50px;
  img {
    height: 100%;
  }
}

@keyframes show-trash {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50px);
  }
}
.slide-trash-in {
  animation: show-trash 0.5s linear both;
}

@keyframes hide-trash {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0px);
  }
}
.slide-trash-out {
  animation: hide-trash 0.5s linear both;
}

.message-item-right {
  margin-right: 20px;
  padding: 8px 0 8px 0;
  display: flex;
  color: #27282c;
  line-height: 16px;
  font-size: 14px;
  flex-direction: column;
  align-items: flex-end;
  .message-info {
    font-size: 0.8em;
    align-items: center;
    display: flex;
    margin-bottom: 3px;

    .message-info-text {
      font-size: 10px;
      flex-direction: column;
      display: flex;
      align-items: flex-end;
      line-height: 130%;

      .message-info-text-title {
        text-transform: capitalize;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
      }
    }
    .message-avatar {
      background: url("/assets/images/user_bg.png");
      border-radius: 50% 50% 0 50%;
      margin-left: 5px;
      width: 27px;
      height: 27px;
      display: flex;
      justify-content: center;
      img {
        border-radius: 50% 50% 0 50%;
        width: 100%;
        height: 100%;
      }
      .message_avatar_user {
        border-radius: 50% 50% 0 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .message-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;

    .message-settings {
      font-size: 14px;
      margin-right: 5px;
      box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
      border-radius: 15px;
      padding: 6px 8px 4px 8px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      min-width: 90px;
      min-height: 29px;
      .message-settings-icon {
        opacity: 0.8;
        width: 50%;
        text-align: center;
      }
    }

    .message-value {
      min-height: 29px;
      font-size: 14px;
      max-width: 230px;
      background-color: #cce7f8;
      box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
      border: 1px solid #a4d7f7;
      border-radius: 10px 0px 10px 10px;
      padding: 6px 10px 5px 10px;
    }
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-200px) scale(0);
    opacity: 0;
  }
}
.slide-out-left {
  animation: slide-left 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
}
@keyframes slide-top-left {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  13% {
    transform: translate(10, -40px) scale(0.87);
    opacity: 0.875;
  }
  25% {
    transform: translate(20, -75px) scale(0.75);
    opacity: 0.75;
  }
  50% {
    transform: translate(0, -150px) scale(0.5);
    opacity: 0.625;
  }
  63% {
    transform: translate(-30, -175px) scale(0.37);
    opacity: 0.5;
  }
  75% {
    transform: translate(-100px, -200px) scale(0.25);
    opacity: 0.375;
  }
  88% {
    transform: translate(-150px, -190px) scale(0.12);
    opacity: 0.25;
  }
  100% {
    transform: translate(-200px, -180px) scale(0);
    opacity: 0;
  }
}
.slide-out-top-left {
  animation: slide-top-left 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
}

.message-item-left {
  margin-left: 20px;
  padding: 8px 0 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .message-info {
    font-size: 0.8em;
    align-items: center;
    display: flex;
    margin-bottom: 3px;
    flex-direction: row-reverse;
    .message-info-text {
      font-size: 10px;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      line-height: 130%;
      .message-info-text-title {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .message-avatar {
      background: url("/assets/images/user_bg.png");
      border-radius: 50% 50% 50% 0;
      margin-right: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      img {
        border-radius: 50% 50% 50% 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .message-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;

    .message-value {
      font-size: 14px;
      max-width: 230px;
      background-color: #faf5f6;
      box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
      border: 1px solid #ff9ca1;
      border-radius: 0px 10px 10px 10px;
      padding: 3px 10px 3px 10px;
    }
  }
  .message-option {
    font-family: Roboto, serif;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
    font-size: 14px;
    color: white;
    background-color: #3fa5ff;
    border-radius: 4px;
    border: 1px solid #aac8ff;
    min-width: 50%;
    min-height: 40px;
    margin: 10px 0px 0px 0px;
  }
  .chat-map {
    min-width: 90%;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
    // img {
    //   border-radius: 0 8px 8px 8px;
    //   object-fit: contain;
    //   height: 100%;
    //   width: 100%;
    // }
  }

  .chat-map_preview {
    max-width: 100%;
    // border-radius: 0 8px 8px 8px;
    // box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
    // img {
    //   border-radius: 0 8px 8px 8px;
    //   object-fit: contain;
    //   height: 100%;
    //   width: 100%;
    // }
  }
  .rate-block {
    padding-right: 20px;
    .rate-message {
      width: 145px;
      color: #27282c;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 16px;
      margin-top: 5px;
      border: 1px solid #d8dde1;
      border-radius: 10px;
      background-color: #f7f9fa;
      box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
      padding: 6px 6px 6px 6px;
    }
    .rate-stars {
      min-width: 160px;
      margin-top: 5px;
      border: 1px solid #aac8ff;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
      padding: 4px 8px 4px 8px;
      span {
        display: flex !important;
        justify-content: space-between !important;
      }
    }
  }
}
.chat-date-divider {
  z-index: 1;
  padding: 2px 5px 2px 5px;
  font-size: 12px;
  color: #707070;
  font-style: italic;
  letter-spacing: 0.33px;
  line-height: 14px;
  text-align: center;
  max-width: 80px;
  border-radius: 2px;
  height: 18px;
  background-color: #f4f4f4;
  margin: 0px auto;
  margin-top: 20px;
}

.history-date-divider {
  z-index: 1;
  padding: 2px 5px 2px 5px;
  font-size: 12px;
  color: white;
  font-style: italic;
  letter-spacing: 0.33px;
  line-height: 14px;
  text-align: center;
  max-width: 150px;
  border-radius: 2px;
  height: 18px;
  background-color: #3fa5ff;
  margin: 0px auto;
  margin-top: 20px;
}

.history-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;

  .history-settings {
    font-size: 14px;
    margin-right: 5px;
    box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
    border-radius: 15px;
    padding: 6px 8px 4px 8px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    min-width: 40px;
    min-height: 29px;
    .history-settings-icon {
      opacity: 0.8;
      text-align: center;
    }
  }

  .history-value {
    min-height: 29px;
    font-size: 14px;
    max-width: 230px;
    background-color: #cce7f8;
    box-shadow: 0 2px 8px 0 rgba(19, 21, 23, 0.12);
    border: 1px solid #a4d7f7;
    border-radius: 10px 0px 10px 10px;
    padding: 6px 10px 5px 10px;
  }
}
