/* BookcolNavbarCSS.css */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

.white_bgr {
  background-color: transparent;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 127px;
  position: absolute;
  clip-path: inset(0 0 88% 0);
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
}

/* GREY */

.orders_grey {
  background-color: grey;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 0;
  clip-path: inset(0 0 74vh 58%);
  position: absolute;
  /* text positioning */
  color: white;
  font-weight: 500;
  box-sizing: border-box;
  padding: 0 18vw 74vh 58vw;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.orders_grey > span {
  word-spacing: -0.3em;
  align-self: center;
  text-align: center;
}

.store_grey {
  background-color: grey;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 0;
  clip-path: inset(0 43% 74vh 28%);
  position: absolute;
  /* text positioning */
  color: white;
  font-weight: 500;
  box-sizing: border-box;
  padding: 0px 43vw 74vh 28vw;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.store_grey > span {
  word-spacing: -0.3em;
  align-self: center;
  text-align: center;
}

.library_grey {
  background-color: grey;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 0;
  clip-path: inset(0 73% 74vh 0);
  position: absolute;
  /* text positioning */
  color: white;
  font-weight: 500;
  box-sizing: border-box;
  padding: 0 73vw 74vh 0;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.library_grey > span {
  word-spacing: -0.3em;
  align-self: center;
  text-align: center;
}

/* WHITE */

.orders_white_ord {
  background-color: white;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 0;
  clip-path: inset(0 0 72vh 58%);
  position: absolute;
  display: block;
  /* text positioning */
  color: black;
  font-weight: 500;
  box-sizing: border-box;
  padding: 0 18vw 74vh 58vw;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.orders_white_ord > span {
  word-spacing: -0.3em;
  align-self: center;
  text-align: center;
}

.library_white_lib {
  background-color: white;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 0;
  clip-path: inset(0 73% 72vh 0);
  position: absolute;
  display: block;
  /* text positioning */
  color: black;
  font-weight: 500;
  box-sizing: border-box;
  padding: 0 73vw 74vh 0;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.library_white_lib > span {
  word-spacing: -0.3em;
  align-self: center;
  text-align: center;
}

.store_white_sto {
  background-color: white;
  border-radius: 0 25vw 0 0;
  height: 80vh;
  width: 100%;
  top: 0;
  clip-path: inset(0 43% 72vh 28%);
  position: absolute;
  display: block;
  /* text positioning */
  color: black;
  font-weight: 500;
  box-sizing: border-box;
  padding: 0px 43vw 74vh 28vw;
  line-height: 1;
  display: flex;
  justify-content: center;
}

.store_white_sto > span {
  word-spacing: -0.3em;
  align-self: center;
  text-align: center;
}

@media only screen and (max-width: 330px) {
  .white_bgr {
    font-size: 15px;
  }
}

@media only screen and (max-height: 645px) {
  .white_bgr {
    font-size: 15px;
  }
}
