.caa_main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .caa_title {
    align-self: center;
  }
  .caa_finish_btn {
    align-self: center;
    margin-top: 10px;
  }
  .caa_container {
    display: grid;
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g h";
    grid-auto-columns: 30px auto;
    .fake_vertical_line {
      width: 5px;
      height: 100%;
    }
    .caa_title {
      font-size: 20px;
      font-weight: bold;
    }
    .fake_dot {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      top: 0px;
    }
    .caa_lp_1 {
      position: relative;
      display: flex;
      justify-content: center;
      grid-area: a;
      height: 100%;
    }
    .caa_lp_2 {
      position: relative;
      display: flex;
      justify-content: center;
      grid-area: c;
      height: 100%;
    }
    .caa_lp_3 {
      position: relative;
      display: flex;
      justify-content: center;
      grid-area: e;
      height: 100%;
    }
    .caa_lp_4 {
      position: relative;
      display: flex;
      justify-content: center;
      grid-area: g;
      height: 100%;
    }
    .caa_pp_1 {
      grid-area: b;
      padding-left: 20px;
    }
    .caa_pp_2 {
      grid-area: d;
      padding-left: 20px;
    }
    .caa_pp_3 {
      grid-area: f;
      padding-left: 20px;
    }
    .caa_pp_4 {
      grid-area: h;
      padding-left: 20px;
    }
    .cca_float_right {
      float: right;
    }
  }
}

.caa_button {
  border-radius: 6px;
  height: 0px;
  line-height: 0px;
  color: #ffffff;
  font-family: Open Sans;
  width: 200px;
  font-size: 16px;
  font-weight: 700;
  padding: 21px;
  background-color: #3b99f6;
  box-shadow: 1px 1px 5px 0px #000000;
  border: solid #379eed 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 20px;
}

.caa_button:hover {
  border: solid #337fed 1px;
  background: #1e62d0;
  background-image: linear-gradient(to bottom, #1e62d0, #3d94f6);
  border-radius: 6px;
  text-decoration: none;
}

.caa_button_disabled {
  border-radius: 6px;
  height: 0px;
  line-height: 0px;
  color: #ffffff;
  font-family: Open Sans;
  width: 200px;
  font-size: 16px;
  font-weight: 700;
  padding: 21px;
  background-color: #77c6f3;
  box-shadow: 1px 1px 5px 0px #818181;
  border: solid #95dbf7 1px;
  text-decoration: none;
  display: inline-block;
  cursor: default;
  margin-bottom: 20px;
}
