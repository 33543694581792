#ScanAndBarcode {
  position: absolute;
  height: calc(100vh - 60px);
  /* min-height: 630px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  width: 100vw;
}

#bcas_top_section {
  background-color: grey;
  height: 50%;
  color: white;
}

.bcas_scan_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.bcas_scan_title {
  align-self: center;
  font-size: 25px;
}
.bcas_scan_img {
  align-self: center;
  width: 175px;
}
.bcas_scan_text {
  padding: 0 10px;
  align-self: center;
  text-align: center;
}

#bcas_bottom_section {
  background-color: white;
  height: 50%;
  text-align: center;
  padding: 0 10px;
}

.bcas_bs_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.bcas_title_text {
  align-self: center;
  /* margin-top: 15px; */
}
.bcas_barcode_img {
  align-self: center;
  border: 2px solid black;
  padding: 0 20px;
}
.bcas_bottom_text {
  align-self: center;
  /* margin: 10px 52px; */
  text-align: center;
}

#bcas_bottom_section > .react-card-flip {
  height: 100%;
  width: 100%;
}

#bcas_video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.bcas_error_msg_wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.bcas_error_msg_div {
  align-self: center;
}
