/* TableEdit.css */

#TableEdit {
  background-color: white;
  height: 100vh;
  width: 100vw;
}

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border-right: 1px solid #ddd;
  padding: 4px 8px 4px 8px;
  min-width: 100px;
  transition: all 0.3s ease-out;
}
#customers tr:nth-child(odd) {
  background-color: #f2f5fa;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  background-color: #fff;
  color: #545669;
  min-width: 100px;
  vertical-align: top;
}

.info_row {
  text-transform: none;
  font-size: 12px;
  line-height: normal;
  vertical-align: top;
}

#customers td input:-ms-input-placeholder {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.box_table_name {
  width: 99%;
  background-color: rgba(255, 192, 203, 0.9);
  border: solid 3px darkblue;
  text-align: left;
  font-size: 15pt;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.box_table_comment {
  width: 99%;
  background-color: lightyellow;

  text-align: left;
  font-size: 11pt;
  font-style: italic;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#customers input {
  width: 100%;
  border: none;
  background-color: transparent;
}

.tableEdit_title {
  font-size: 25px;
  font-weight: 500;
}

.tableEdit_title_wrapper {
  display: flex;
  justify-content: space-between;
}

.tableEdit_title::first-letter {
  text-transform: uppercase;
}

.tableEdit_top_back_arrow {
  /* filter: invert(100%); */
  padding: 3px 15px 3px 0px;
}

.tableEdit_tables_container {
  overflow: auto;
  width: 98%;
  margin: 10px auto;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 18px -4px rgba(0, 0, 0, 0.14);
}

.tableEdit_reveal_comments_btn {
  display: flex;
  justify-content: space-between;
}

/* .table_edit_th_comments { */
/* font-size: 12px; */
/* padding: 0; */
/* } */

.raw_data_btn {
  height: 24px;
  width: 48px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #fff;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.raw_data_search{
  display: flex;
  position: relative;
  width: 176px;
  margin: 5px;
}
.raw_data_search input{
  border: 1px solid #E4E4E4;
  border-radius: 7px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.14);
  background-color: #FFF;
  height: 34px;
  width: 100%;
  padding: 3px 27px 0px 5px;
}
.raw_data_search_clean{
  right: 3px;
  top:3px;
  z-index: 100;
  position: absolute;
}