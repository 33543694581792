/* App.css */

.navbar_container {
    display: flex;
    justify-content: center;
}

#dropdown-basic {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
}

.dropdown {
    height: 27px;
    background-color: transparent;
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}