/* BookcolOrderTab.css */

/* bot_ => prefix for BookcolOrderTab */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolOrderTab {
  position: absolute;
}
.swipe_hint{
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
}
.bot_round_corner_background {
  background-color: white;
  border-radius: 0px 25vw 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  clip-path: inset(7vh 0 0 0);
  padding-top: 7vh;
}

#bot_top_bookshelf_bg {
  width: 100vw;
}

.bot_top_title {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.bot_book_data_wrapper {
  padding: 20px;
}

.bot_item_ordered {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Ubuntu", sans-serif;
}

.bot_title {
  font-weight: bold;
  font-size: 18px;
  max-height: 54px;
  overflow-y: auto;
}

.bot_publisher {
  margin-bottom: 10px;
  font-size: 15px;
  margin-left: 17px;
}

.bot_item_ordered_inner_wrapper {
  display: flex;
}

.bot_item_image {
  border: 1px solid lightgray;
  height: 170px;
  min-width: 100px;
  margin-bottom: 20px;
  background: transparent url("/assets/images/loading.gif") center no-repeat;
}

.bot_line {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.bot_line_light {
  border: 0;
  height: 2px;
  margin-bottom: 5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
}

.bot_item_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
}

.bot_order_time {
  margin-top: 20px;
  font-size: 15px;
}

.bot_prog_bar_base {
  height: 25px;
  border: 2px solid rgb(87, 87, 87);
  border-radius: 7px;
}

.bot_prog_bar_runner {
  border-radius: 4px;
  height: 21px;
  overflow: hidden;
  font-size: 14px;
  padding-left: 10px;
}

.bot_ordered_outer_wrapper {
  margin-bottom: 10px;
}

.bot_details_btn {
  float: right;
  font-weight: bold;
  margin-top: 9px;
  cursor: pointer;
}

.bot_ordered_inner_wrapper > input {
  background-color: azure;
}

.bot_note_to_shop {
  width: 100%;
}

.bot_ordered_list {
  font-size: 12px;
}

.bot_ordered_list li {
  margin-left: 12px;
  margin-bottom: 5px;
  border: 1px solid grey;
  padding: 0 5px;
}

.bot_ordered_list > li > div > mark {
  font-weight: bold;
  background-color: transparent;
  padding: 0;
}

.bot_statusHistoryBtn {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
}

.bot_statusHistoryBtn_inactive {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  color: grey;
}

/* break */

/*
.white_bgr1 {
  background-color: transparent;
  border-radius: 0 130px 0 0;
  height: 9vh;
  width: 100vw;
  top: 127px;
  position: absolute;
  display: flex;
  justify-content: stretch;
}

.orders_grey1 {
  background-color: white;
  border-radius: 0 130px 0 0;
  min-height: 130px;
  color: black;
  font-size: 20px;
  font-weight: bold;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100px;
  display: flex;
  justify-content: center;
  clip-path: inset(0 0 50% 0);
}

.orders_grey1 > span {
  margin-left: -57px;
}

.store_grey1 {
  background-color: grey;
  height: 13.5vmin;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-sizing: border-box;
  flex-grow: 1;
  margin: 0 1.5vmin;
  display: flex;
  justify-content: center;
}

.library_grey1 {
  background-color: grey;
  height: 13.5vmin;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-sizing: border-box;
  flex-grow: 1;
  width: 50px;
  display: flex;
  justify-content: center;
}

.bot_top_menu_span {
  margin-top: 12px;
} */
