.main_details_block{
    padding: 5px;
    width: 100%;
    position: relative;
    text-align: left;
    background-color: #fff;
}
.details_table{
    display: flex;
    justify-content: space-between;
}
.left_col{
    flex:2;
    padding-right: 20px;
}
.right_col{
    flex:1
}
.details_table_item{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color:#414248;
    font-size: 0.75rem;
    line-height: 16px;
    margin: 10px 0 0 0;
}