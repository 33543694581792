.new_design_page_block {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: white;
  position: relative;
  transition: padding;
  transition-duration: 0.5s;
}
.menu_search_container {
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.search_container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px;
}
.permissions_arrow_top {
  width: 20px;
  transform: rotate(90deg);
  margin-left: 10px;
  cursor: pointer;
}

.permissions_arrow {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.hubsite_permissions_cell_visible {
  color: black !important;
}

.hubsite_permissions_cell_invisible {
  color: #b2b2b2 !important;
}

.modeBtn {
  color: #000;
  border: none;
  background-color: transparent;
  position: absolute;
}

@media (max-width: 350px) {
  .modeBtn {
    top: 20%;
    right: 4%;
  }
}

@media (min-width: 351px) and (max-width: 400px) {
  .modeBtn {
    top: 18%;
    right: 4%;
  }
}
@media (min-width: 401px) and (max-width: 530px) {
  .modeBtn {
    top: 15%;
    right: 4%;
  }
}

@media (min-width: 531px) {
  .modeBtn {
    top: 2.5%;
    right: 2.5%;
  }
}
.logout_modal_title {
  color: #232326;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.69px;
  line-height: 25px;
  margin: 10px 0 10px 0;
}

.logout_modal_content_text {
  color: #ea8e02;
}
.logout_modal_content_title {
  color: #ea8e02;
}
.logout_modal_content_bottom_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.logout_modal_content_bottom_item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout_modal_content_bottom_item button {
  margin: 20px 0 10px 0;
}

.logout_modal_content_bottom_item button.btn_white {
  height: 40px;
  width: 156px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  border: 1px solid #aac8ff;
  border-radius: 4px;
  color: #3fa4ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.logout_modal_content_bottom_item button.btn_blue {
  height: 41px;
  width: 157px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.back_to_hub_text {
  font-size: 13px;
  width: fit-content;
  min-width: 33.5vw;
  padding: 20px;
  color: #3fa5ff;
  font-weight: bold;
}

@media (min-width: 500px) {
  .visit_panel_right_block {
    width: 33.3vw;
  }
}

@media (max-width: 500px) {
  .visit_panel_right_block {
    width: 0vw;
  }
}

.searchIcon {
  margin-left: auto !important;
}
.hub_number {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 16px;
}
@media (min-width: 520px) {
  .right_block {
    width: 119px;
  }
}

.hubsite_covid_mod_btn {
  border: 1px solid #3fa4ff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  background-color: #3fa4ff;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  float: right;
}

.hubsite_covid_mod_btn_wrpr {
  position: absolute;
  right: 65px;
  top: 170px;
}

@media all and (max-width: 390px) {
  .hubsite_covid_mod_btn {
    font-size: 12px;
    padding: 0 3px;
    height: 30px;
  }

  .hubsite_covid_mod_btn_wrpr {
    position: absolute;
    right: 15px;
    top: 210px;
  }
}

/* covidbanner */

.hubsite_covid_banner_container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.hubsite_covid_banner_wrapper {
  width: 90%;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 22px;
}

.hubsite_covid_banner_text1 {
  color: #545669;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
}
.hubsite_covid_banner_text2 {
  color: #414248;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 11px;
}

.hubsite_covid_banner_links_wrap {
  display: flex;
  flex-wrap: wrap;
}

.hubsite_covid_banner_links {
  color: #3fa5ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-right: 26px;
  margin-bottom: 15px;
}

.hubsite_covid_banner_btn_wrap {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.hubsite_covid_banner_btn {
  height: 53px;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hubsite_covid_banner_btn_title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}
