.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped {
  border: none !important;
  background-color: white;
  border-radius: 0px;
}
.MuiToggleButton-label {
  font-size: 12px !important;
  text-transform: capitalize !important;
}
.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped {
  color: #000;
}
.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.Mui-selected {
  border-bottom: 2px solid #3fa5ff !important;
  color: #3fa5ff !important;
}
.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.Mui-selected:focus {
  background-color: #fff;
  color: #3fa5ff !important;
}
.MuiToggleButtonGroup-grouped {
  height: 25px !important;
  width: 100% !important;
  padding: 0px 5px 0px 5px !important;
}
.MuiExpansionPanelSummary-content.Mui-expanded p {
  color: white !important;
}
