/* imprint.css */

.imprint_wrapper {
  display: flex;
  justify-content: center;
}

.imprint_button {
  font-size: 16px;
  color: black;
  align-self: center;
  cursor: pointer;
}
