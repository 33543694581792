.films_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}
.films_title_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.films_list_container {
  width: 100%;
  height: 100%;
}
.films_list {
  display: flex;
  flex-direction: column;
}

.films_list_item {
  font-family: Roboto;
  width: 100%;
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: column;
}

.films_list_item_title {
  font-weight: bold !important;
  font-size: 11px;
}
.films_list_item_details {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
}
.films_list_item_details div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
}
.films_list_item_details button {
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #3fa5ff;
}
.films_list_item_details_item {
  min-width: fit-content;
  height: 20px;
  margin-right: 5px;
}
.films_list_item_details_item b {
  font-weight: bold;
}
.films_inputs_container {
  display: flex;
  flex-wrap: wrap;
}

.films_inputs_container input {
  width: 24%;
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding-left: 5px;
}
.films_submit_container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.films_submit_container button {
  transition: 0.5s all;
  border: 1px solid #aac8ff;
  border-radius: 4px;
  background-color: #3fa5ff;
  box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  color: #fff;
  font-family: Roboto;
}
.films_submit_container .rotate {
  border-radius: 50%;
  padding: 2px 2px 2px 2px;
  transform: rotate(360deg);
}
.films_inputs_title {
  font-size: 11px;
  margin: 10px 0 5px;
}
.films_input_item {
  font-size: 11px;
  min-height: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
}
.films_input_item.title {
  width: 60%;
}
.films_input_item.date {
  width: 40%;
}
.films_input_item.rating {
  margin: 10px auto 0;
  box-shadow: none;
  border: none;
}
.films_input_item.rating span:nth-child(5) {
  margin-right: 10px;
}
.films_input_item.note {
  border: 1px solid lightgrey;
  border-radius: 7px;
  width: 100%;
}
.films_list_item_details_item img {
  height: 11px !important;
  margin-top: -2px;
}

.films_search_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.films_search_container input {
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  width: 200px;
  padding: 2px 4px 0 6px;
}
