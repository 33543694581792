
.btn-group{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0 2.5% 0 2.5%;
}
.btn-item{
    height: 33px;
	width: 99px;
	border: 1px solid #AAC8FF;
	border-radius: 4px;
	background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(25,50,89,0.2);
    color: #3FA4FF;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}

.sort_arrow{
    height: 14px;
    margin-left: 3px;
    margin-top: -2px;
}