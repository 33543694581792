.finish-chat-poll-block {
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-bottom: 60px;
  font-family: Roboto, sans-serif;
  position: relative;
  .title {
    color: #3b3c3f;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.44px;
    line-height: 19px;
    margin-bottom: 20px;
  }
  .list-item {
    margin-top: 10px;
    color: #636363;
    font-size: 14px;
    line-height: 16px;
  }
  .btn-block {
    margin-top: 10px;
    display: flex;
    .btn {
      height: 41px;
      width: 89px;
      border-radius: 4px;
      border: 1px solid #aac8ff;
      box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
    }
    .white {
      background-color: #ffffff;
      color: #676767;
    }

    .blue {
      margin-right: 10px;
      background-color: #3fa5ff;
      color: #ffffff;
    }
  }
  .phone-img {
    position: absolute;
    height: 151px;
    width: 74px;
    right: 15vw;
    bottom: 60px;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

@keyframes moveIn {
  from {
    transform: translateX(-300px);
   }
   to {
    transform: translateX(0);
   }
}
@keyframes moveOut {
  from {
    transform: translateX(0);
   }
   to {
    transform: translateX(350px);
   }
}

.modal_animation_moveIn{
  animation-name: moveIn;
  animation-duration: 0.5s;
}
.modal_animation_moveOut{
  animation-name: moveOut;
  animation-duration: 0.5s;
}
.modal-block{
  top: 40%;
  left: 18%;
  box-sizing: border-box;
  border: 1px solid #D0D7E4;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.14);
  position: absolute;
  height: 211px;
  width: 268px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .answer_icon{
    height: 52px;
    width: 52px;
  }
  .main-text{
    width: 100%;
    text-align: center;
    margin: 15px 0 25px 0;
  }
  .modal-button{
    height: 40px;
    width: 134px;
    border: 1px solid #AAC8FF;
    border-radius: 4px;
    background-color: #3FA5FF;
    box-shadow: 0 2px 4px 0 rgba(25,50,89,0.2);
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}