.cov_faq_container {
  width: 100%;
  font-family: Roboto;
  height: 100vh;
  background-color: #fff;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cov_faq_header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.cov_faq_back {
  position: absolute;
  left: 10px;
  top: 10px;
}
.about_info_p1 {
  text-align: center;
  font-size: 25px;
}
.about_info_img {
  position: relative;
}
.about_info_p2 {
  font-size: 20px;
}
.cov_faq_title {
  font-size: 30px;
  font-weight: bold;
}
.cov_faq_text {
  margin: 10px 30px 10px 50px;
}

@media all and (max-width: 720px) {
  .cov_faq_header {
    justify-content: flex-end;
  }
  .cov_faq_title {
    margin-right: 20px;
  }
}

@media all and (max-width: 670px) {
  .cov_faq_header {
    justify-content: center;
  }
  .cov_faq_title {
    margin: 30px 10px 0;
    text-align: center;
  }
}
