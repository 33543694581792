/* receipt_form_navigation.css */

#receipt_index_navigation_container {
margin: 10px auto;
display: flex;
justify-content: space-between;
width: 320px;
}

#receipt_index_navigation_container > button {
    width: 60px;
}

#receipt_form_navigation_container {
    display: flex;
    justify-content: center;
    width: 320px;
    margin: 0 auto;
}

#receipt_form_navigation_container > img {
    width: 50px;
    height: 50px;
    margin: 5px;
}