.injuries_block{
    width: 100%;
    position: relative;
    border-radius: 4px;
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F7FA;
    border-radius: 4px;
}

.injuries_header{
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0 10px 0 10px;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.injuries_header.grey{
    background-color: #545669;
}

.injuries_header.green{
    background-color: #6ECD6C;
}

.short_layout_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #545669;	
    font-family: Roboto;	
    font-size: 14px;	
    font-weight: 500;	
    line-height: 16px;
}
.short_layout_container button{
    color: #3FA4FF;	
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
    border: none;
    background-color: transparent;
    margin: 3px;
}
.edit_layout_container{
    overflow: hidden;
    display: flex;
    transition: 0.5s all;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.edit_layout_input_container{
    width: fit-content;
    margin-right: 5px;
}
.edit_layout_input_block{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.edit_layout_input_block input{
    text-align: center;
    height: 36px;	
    width: 20px;	
    border: 1px solid #F1F1F1;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    margin: 0 2px;
    font-weight: bold;
}
.edit_layout_input_controls{
    display: flex;
    flex-direction: column;
}
.edit_layout_input_controls button{
    overflow: hidden;
    height: 17px;	
    width: 28px;	
    border: 1px solid #3FA4FF;	
    border-radius: 4px;	
    background-color: #FFFFFF;
}

.edit_layout_input_controls button .MuiSvgIcon-root{
    margin-top: -7px;
    color:#3FA4FF;
}

.edit_layout_note_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5px;
    color: #414248;	
    font-family: Roboto;	
    font-size: 12px;	
    font-weight: 500;	
    line-height: 14px;
    width: 100%;
    margin-left: 5px;
}
.edit_layout_note_container input{
    height: 36px;	
    color: #414248;	
    border: 1px solid #F1F1F1;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    font-family: Roboto;	
    font-size: 12px;	
    line-height: 14px;
    width: 100%;
    padding-left: 5px;
}
.injuries_footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 5px 5px 0;
}
.injuries_footer button:first-child{
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 3px 8px 3px 8px;
    border: 1px solid #aac8ff;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
    color: #3fa5ff;
    font-family: Roboto;
    margin-right: 5px;
}
.injuries_footer button:last-child{
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 3px 8px 3px 8px;
    border: 1px solid #aac8ff;
    border-radius: 4px;
    background-color: #3fa5ff;
    box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
    color: #fff;
    font-family: Roboto;
}
.injuries_prev_container{
    display: flex;
    width: 100%;
    transition: 0.3s all;
    overflow: hidden;
    padding-left: 5px;
}
.injuries_prev_value{
    width: fit-content;
    white-space: nowrap;
}
.injuries_prev_value span{
    color: #414248;	
    font-family: Roboto;	
    font-size: 12px;	
    font-weight: 500;	
    line-height: 14px;
}
.injuries_prev_value div{
    height: 36px;	
    width: 20px;	
    border: 1px solid #F1F1F1;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    color: #545669;	
    font-family: Roboto;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.injuries_prev_note{
    width: 100%;
    padding-right: 15px;
}

.injuries_prev_note span{
    color: #414248;	
    font-family: Roboto;	
    font-size: 12px;	
    font-weight: 500;	
    line-height: 14px;
}
.injuries_prev_note div{
    color: #414248;	
    font-family: Roboto;	
    font-size: 12px;	
    font-style: italic;	
    line-height: 14px;
}