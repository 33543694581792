/* settings.css */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#Settings {
  position: fixed;
  bottom: 60px;
  right: 0;
  height: 300px;
  width: 200px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  z-index: 5;

  animation: Settings_entrance 0.5s 1;
  animation-timing-function: ease;
}

@keyframes Settings_entrance {
  from {
    opacity: 0;
    height: 100px;
  }
  to {
    opacity: 1;
    height: 300px;
  }
}

.set_content_wrapper {
  padding: 5px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
}

#set_info_button {
  cursor: pointer;
}

/* logout button */

.set_logoutButton {
  color: black;
  cursor: pointer;
}

.set_close_settings {
  transform: rotate(-90deg);
  height: 25px;
  align-self: center;
  margin-top: 20px;
  cursor: pointer;
}
