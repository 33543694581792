.main-block {
  margin-top: 10px;
  width: 100%;
  padding: 20px;
  display: flex;
  border-top: dashed 1px #84888b;
  border-bottom: dashed 1px #84888b;
  .main-block-button {
    width: 28%;
    height: 55px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  }
  .blue {
    color: #ffffff;
    background-color: #3fa5ff;
    border: 1px solid #aac8ff;
  }
  .white {
    color: #676767;
    background-color: #ffffff;
    border: 1px solid #aac8ff;
  }
}

.save_shop_list_modal{
  font-family: Roboto, sans-serif;
  padding: 20px 10px 10px 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .close_btn{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 10px 0 0;
  }
  .title{
    width: 100%;
    text-align: center;
  }
  .list{
    overflow: scroll;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
  }
  .save_btn{
    margin: 10px auto 0px;
    color: #ffffff;
    background-color: #3fa5ff;
    border: 1px solid #aac8ff;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(25, 50, 89, 0.2);
  }
}