.menu_slider_block {
  position: absolute;
  bottom: 5%;
  right: 2%;
  z-index: 2;
  /* background-color: lightgray; */
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.menu_slider_block img {
  position: absolute;
  top: 0;
  height: 400px;
}
.menu_slider_block button {
  height: 45px;
  width: 45px;
  margin: 5px;
  z-index: 100;
  position: relative;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #a7a7a7;
  border-radius: 50%;
  box-shadow: 3px 6px 8px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.menu_slider_block button img {
  height: 50%;
  width: 50%;
  margin-top: 25%;
}
