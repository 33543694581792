/* BookcolLibraryTab.css */

/* blt_ => prefix for BookcolLibraryTab */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolLibraryTab {
  position: absolute;
  font-family: "Ubuntu", sans-serif;
}

.blt_round_corner_background {
  background-color: white;
  border-radius: 0px 25vw 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  clip-path: inset(7vh 0 0 0);
  padding-top: 7vh;
}

#blt_top_bookshelf_bg {
  width: 100vw;
}

.blt_top_title {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  height: 50px;
  font-weight: bold;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
}

.blt_top_title > span {
  align-self: flex-end;
}

.blt_book_data_wrapper {
  padding: 20px 0 20px 0;
  min-height: 100vh;
}

.blt_line {
  border: 0;
  height: 2px;
  margin-bottom: 5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.blt_line_light {
  border: 0;
  height: 2px;
  margin-bottom: 5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
}

.blt_book_list {
  display: flex;
  width: 100vw;
  overflow-x: auto;
}

.blt_book_wrapper {
  width: 100vw;
}

.blt_item_container {
  height: 150px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.blt_item_image {
  height: 130px;
  min-width: 80px;
  background: transparent url("/assets/images/loading.gif") center no-repeat;
}

.blt_item_container > span {
  align-self: center;
}

.blt_item_container > div {
  align-self: center;
}

.blt_poss_rel_to {
  height: 20px;
  font-size: 13px;
  overflow-x: auto;
  font-weight: 500;
}

.blt_rating_stars {
  color: rgb(223, 152, 0);
}

.blt_search_bar_container {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  position: -webkit-sticky;
  position: sticky;
  height: 50px;
  top: 0;
  z-index: 1;
}

.blt_search_bar_wrapper {
  align-self: center;
  background-color: white;
  margin-right: 20px;
  padding: 13px 0;
  padding-left: 13px;
  border-radius: 0 0 10px 10px;
}

.blt_search_zoom_icon {
  align-self: center;
  height: 20px;
  margin-right: 5px;
}

.blt_searchbook_input {
  width: 110px;
  border-radius: 7px;
  padding-left: 8px;
  border: 1px solid black;
  align-self: center;
  margin-right: 10px;
}

.blt_searchbook_input_del_icon {
  height: 18px;
  top: 15px;
  position: absolute;
  right: 17px;
  background-color: white;
  color: darkgrey;
}
