/* Receipt_shop_icons.css */

/* PREFIX FOR ReceiptShopIcons = rsi_ */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#ReceiptsShopIcons {
  position: absolute;
}

.rsi_round_corner_background {
  background-color: white;
  border-radius: 0px 130px 0px 0px;
  position: absolute;
  top: 127px;
  left: 0;
  width: 100vw;
  min-height: 86vh;
  z-index: 1;
  padding-bottom: 60px;
}

.rsi_top_img_wrapper {
  width: 100vw;
  background-image: url("/assets/images/calculator_img.jpg");
  background-repeat: no-repeat;
  background-size: 127%;
  background-position: -26px -102px;
  height: 290px;
}

.rsi_top_img_wrapper2 {
  width: 100vw;
  background-image: url("/assets/images/navigation_bg_image_fullsize.jpg");
  background-repeat: no-repeat;
  background-size: 127%;
  background-position: -20px -55px;
  height: 290px;
}

.rsi_shop_logo_div {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 10px;
  display: inline-flex !important;
  overflow: hidden;
  justify-content: center;
  vertical-align: top;
}

.rsi_shop_item_wrapper {
  padding: 5px 0 0 5px;
  justify-content: center;
  vertical-align: top;
  display: inline-flex;
  max-width: auto;
  height: 100px;
  padding: 5px;
}

.rsi_shop_logo {
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}

.rsi_big_title {
  font-size: 28px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  margin: 5px 0 0 15px;
}

.rsi_small_title {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  margin: 0 0 0 15px;
}

.rsi_hr_line {
  margin: 15px 15px 5px;
  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(
    radial,
    50% 50%,
    0,
    50% 50%,
    350,
    from(#000),
    to(#fff)
  );
}
