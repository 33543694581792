/* BookcolSearchBook.css */

@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap");

#BookcolSearchBook {
  padding: 0 10px;
  font-family: "Ubuntu", sans-serif;
}

.bsch_search_order_btn {
  height: 43px;
  width: 150px;
  -moz-box-shadow: inset 0px 1px 0px 0px #54a3f7;
  -webkit-box-shadow: inset 0px 1px 0px 0px #54a3f7;
  box-shadow: inset 0px 1px 0px 0px #54a3f7;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #007dc1),
    color-stop(1, #0061a7)
  );
  background: -moz-linear-gradient(top, #007dc1 5%, #0061a7 100%);
  background: -webkit-linear-gradient(top, #007dc1 5%, #0061a7 100%);
  background: -o-linear-gradient(top, #007dc1 5%, #0061a7 100%);
  background: -ms-linear-gradient(top, #007dc1 5%, #0061a7 100%);
  background: linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007dc1', endColorstr='#0061a7',GradientType=0);
  background-color: #007dc1;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #124d77;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 13px;
  padding: 10px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #154682;
}

.bsch_searchbook_input_and_switch_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.bsch_searchbook_input_container {
  display: flex;
  justify-content: space-between;
  min-height: 54px;
  max-height: 54px;
  overflow-y: auto;
}

.bsch_searchbook_input_wrapper {
  display: flex;
  justify-content: center;
  margin: 0 0 auto 0;
}

.bsch_search_zoom_icon {
  align-self: center;
  height: 20px;
  margin-right: 5px;
}

.bsch_searchbook_input {
  width: 110px;
  border-radius: 7px;
  padding-left: 8px;
  border: 1px solid black;
  align-self: center;
}

.bsch_searchbook_input::placeholder {
  font-size: 10px;
}

.bsch_searchbook_text {
  font-size: 12px;
  color: rgb(73, 38, 38);
}

.bsch_search_container {
  display: grid;
  grid-template-areas: "a b";
  padding: 5px;
  font-size: 12px;
  margin-top: 5px;
}

.bsug_suggestion_item {
  display: grid;
  grid-template-areas: "a b";
}

.bsug_suggestion_price_and_stock {
  padding-left: 5px;
  padding-right: 5px;
}

.bsug_suggestion_price_and_stock > p {
  margin: 0;
}

.bsug_suggest_dropd_item {
  color: black;
  padding: 10px;
  border-bottom: 2px solid black;
}

.bsch_sbic_shop_name {
  font-size: 18px;
  font-weight: 500;
}

.sexy_line {
  margin: 0 15px 5px;
  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(
    radial,
    50% 50%,
    0,
    50% 50%,
    350,
    from(#000),
    to(#fff)
  );
}

.mark_red {
  color: red;
  padding: 0;
  background-color: transparent;
}

.bsch_searchbook_switch_wrapper {
  display: flex;
  justify-content: flex-end;
}

.bsch_switch_vendor_btn {
  font-size: 14px;
  font-weight: 500;
  color: #0684df;
}

.bsch_switch_vendor_wrapper {
  flex-direction: column;
  align-items: flex-end;
  background-color: #54a3f7;
  border-radius: 10px;
  padding: 5px;
}

.bsch_switch_vendor_item {
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 319px) {
  .bsch_searchbook_input_container {
    display: block;
    max-height: 200px;
  }

  .bsch_searchbook_input_wrapper {
    display: block;
  }
}
