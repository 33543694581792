.burger_menu_btn{
    border: none;
    background-color: transparent;
    position: relative;
    margin: 10px;
    left: 0;   
}
.menu_icon{
    max-width: 20px;
    max-height: 20px;
}
.hubsite_icon{
    height: 35px;
    width: 35px;
}
.avatar_container{
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 20px;
}
.avatar_img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: transparent;
    margin-right: 5px;
}
.menu_title{
    width: 100%;
}
.MuiListItem-root{
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.MuiDrawer-paperAnchorLeft{
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.menu_list_item{
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.row_menu_item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.row_menu_item.home{
    margin-left: 15px;
}

.row_menu_item img{
    margin-right: 7px;
}

.big_row_menu{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 0 10px 0;
}
.big_row_menu img{
    height: 60px;
}
